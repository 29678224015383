/* eslint-disable import/no-anonymous-default-export */
import {
  PARTNER_STATS_FAIL,
  PARTNER_STATS_SUCCESS,
  WEBSITE_STATS_SUCCESS,
  WEBSITE_STATS_FAIL,
  USER_SIGNUP_STATS_FAIL,
  USER_SIGNUP_STATS_SUCCESS,
  DASHBOARD_OVERVIEW,
  DASHBOARD_OVERVIEW_FAIL,
  DASHBOARD_OVERVIEW_SUCCESS,
  DASHBOARD_STATISTICS_FAIL,
  DASHBOARD_STATISTICS_SUCCESS,
  DASHBOARD_STATISTICS,
  DASHBOARD_GRAPH,
  DASHBOARD_GRAPH_FAIL,
  DASHBOARD_GRAPH_SUCCESS,
} from 'store/actionLabels';

const initialState = {
  partnerData: {},
  websiteData: {},
  userSignupData: {},
  // launchpad
  overviewData: {},
  statisticsData: {},
  graphData: {},
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // chip managmenet
    case PARTNER_STATS_SUCCESS:
      return {
        ...state,
        partnerData: payload,
      };
    case PARTNER_STATS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        content: {},
      };
    }
    case WEBSITE_STATS_SUCCESS:
      return {
        ...state,
        websiteData: payload,
      };
    case WEBSITE_STATS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        content: {},
      };
    }
    case USER_SIGNUP_STATS_SUCCESS:
      return {
        ...state,
        userSignupData: payload,
      };
    case USER_SIGNUP_STATS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        content: {},
      };
    }
    // crypto launchpad
    case DASHBOARD_OVERVIEW:
      return {
        ...state,
        isLoading: true,
      };
    case DASHBOARD_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviewData: payload,
        isLoading: false,
      };
    case DASHBOARD_OVERVIEW_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }

    case DASHBOARD_STATISTICS_SUCCESS:
      return {
        ...state,
        statisticsData: payload,
        isLoading: false,
      };
    case DASHBOARD_STATISTICS:
      return {
        ...state,
        isLoading: true,
      };
    case DASHBOARD_STATISTICS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        content: {},  
        isLoading: false,
      };
    }
    case DASHBOARD_GRAPH_SUCCESS:
      return {
        ...state,
        graphData: payload,
        isLoading: false,
      };
    case DASHBOARD_GRAPH:
      return {
        ...state,
        isLoading: true,
      };
    case DASHBOARD_GRAPH_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        content: {},  
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
