import * as actionLabels from '../../actionLabels';

const initialState = {
    affiliatesList: {},
    affiliateInfo: {},
    affiliateDetailsList: {},
    affiliateInvestments: {},
    exportAffiliateInvst: {},
    affiliateDetailLoading: false,
    isLoading: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
      case actionLabels.GET_AFFILIATE_LIST:
        return {
          ...state,
          isLoading: true,
        };
      case actionLabels.GET_AFFILIATE_LIST_SUCCESS:
        return {
          ...state,
          affiliatesList: payload,
          isLoading: false,
        };
      case actionLabels.GET_AFFILIATE_LIST_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isLoading: false,
          
        };
      }
      case actionLabels.GET_AFFILIATE_INFO:
        return {
          ...state,
          isLoading: true,
        };
      case actionLabels.GET_AFFILIATE_INFO_SUCCESS:
        return {
          ...state,
          affiliateInfo: payload,
          isLoading: false,
        };
      case actionLabels.GET_AFFILIATE_INFO_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isLoading: false,
          
        };
      }
      case actionLabels.GET_AFFILIATE_DETAILS:
        return {
          ...state,
          affiliateDetailLoading: true,
        };
      case actionLabels.GET_AFFILIATE_DETAILS_SUCCESS:
        return {
          ...state,
          affiliateDetailsList: payload,
          affiliateDetailLoading: false,
        };
      case actionLabels.GET_AFFILIATE_DETAILS_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          affiliateDetailLoading: false,        
        };
      }
      case actionLabels.GET_AFFILIATE_INVESTMENT:
        return {
          ...state,
          isLoading: true,
        };
      case actionLabels.GET_AFFILIATE_INVESTMENT_SUCCESS:
        return {
          ...state,
          affiliateInvestments: payload,
          isLoading: false,
        };
      case actionLabels.GET_AFFILIATE_INVESTMENT_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isLoading: false,
        };
      }
      case actionLabels.EXPORT_AFFILIATE_INVESTMENT:
        return {
          ...state,
          isLoading: true,
        };
      case actionLabels.EXPORT_AFFILIATE_INVESTMENT_SUCCESS:
        return {
          ...state,
          exportAffiliateInvst: payload,
          isLoading: false,
        };
      case actionLabels.EXPORT_AFFILIATE_INVESTMENT_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isLoading: false,
        };
      }
      default:
        return state;
    }
  };
