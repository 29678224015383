import * as actionLabels from '../../actionLabels';

export const getTokenGenerationFeeSaga = payload => ({
  type: actionLabels.GET_TOKEN_GENERATION_FEE,
  payload,
});

export const getTokenGenerationFeeSagaFail = payload => ({
  type: actionLabels.GET_TOKEN_GENERATION_FEE_FAIL,
  payload,
});

export const getTokenGenerationFeeSagaSuccess = payload => ({
  type: actionLabels.GET_TOKEN_GENERATION_FEE_SUCCESS,
  payload,
});

export const getPlatformFeeSaga = payload => ({
  type: actionLabels.GET_PLATFORM_FEE_LIST,
  payload,
});

export const getPlatformFeeSagaFail = payload => ({
  type: actionLabels.GET_PLATFORM_FEE_LIST_FAIL,
  payload,
});

export const getPlatformFeeSagaSuccess = payload => ({
  type: actionLabels.GET_PLATFORM_FEE_LIST_SUCCESS,
  payload,
});

export const getListingFeeSaga = payload => ({
  type: actionLabels.GET_LISTING_FEE_LIST,
  payload,
});

export const getListingFeeSagaFail = payload => ({
  type: actionLabels.GET_LISTING_FEE_LIST_FAIL,
  payload,
});

export const getListingFeeSagaSuccess = payload => ({
  type: actionLabels.GET_LISTING_FEE_LIST_SUCCESS,
  payload,
});
export const getUserListSaga = payload => ({
  type: actionLabels.GET_USER_LIST,
  payload,
});

export const getUserListSagaFail = payload => ({
  type: actionLabels.GET_USER_LIST_FAIL,
  payload,
});

export const getUserListSagaSuccess = payload => ({
  type: actionLabels.GET_USER_LIST_SUCCESS,
  payload,
});
export const transactionExportListSaga = payload => ({
  type: actionLabels.TRANSACTION_EXPORT_LIST,
  payload,
});

export const transactionExportListSagaFail = payload => ({
  type: actionLabels.TRANSACTION_EXPORT_LIST_FAIL,
  payload,
});

export const transactionExportListSagaSuccess = payload => ({
  type: actionLabels.TRANSACTION_EXPORT_LIST_SUCCESS,
  payload,
});

export const exportListingFeeSaga = payload => ({
  type: actionLabels.EXPORT_LISTING_FEE,
  payload,
});

export const exportListingFeeSagaFail = payload => ({
  type: actionLabels.EXPORT_LISTING_FEE_FAIL,
  payload,
});

export const exportListingFeeSagaSuccess = payload => ({
  type: actionLabels.EXPORT_LISTING_FEE_SUCCESS,
  payload,
});

export const exportTokenGenerationFeeSaga = payload => ({
  type: actionLabels.EXPORT_TOKENGENERATION_FEE,
  payload,
});

export const exportTokenGenerationFeeSagaFail = payload => ({
  type: actionLabels.EXPORT_TOKENGENERATION_FEE_FAIL,
  payload,
});

export const exportTokenGenerationFeeSagaSuccess = payload => ({
  type: actionLabels.EXPORT_TOKENGENERATION_FEE_SUCCESS,
  payload,
});

export const exportPlatformFeeSaga = payload => ({
  type: actionLabels.EXPORT_PLATFORM_FEE,
  payload,
});

export const exportPlatformFeeSagaFail = payload => ({
  type: actionLabels.EXPORT_PLATFORM_FEE_FAIL,
  payload,
});

export const exportPlatformFeeSagaSuccess = payload => ({
  type: actionLabels.EXPORT_PLATFORM_FEE_SUCCESS,
  payload,
});

export const clearExportDataSaga = payload => ({
  type: actionLabels.CLEAR_EXPORT_DATA,
  payload,
});
