import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/reducer';

import {
  watchAuthentication,
  watchDashboard,
  watchUser,
  watchProject,
  watchProfile,
  watchPlatform,
  watchTransaction,
  watchAffiliate,
  watchCms,
  watchNotification,
} from 'store/sagas';
import { watchSwap } from './sagas';

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  //   { auth: { ...loadState() } },
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(watchAuthentication);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchProject);
sagaMiddleware.run(watchProfile);
sagaMiddleware.run(watchPlatform);
sagaMiddleware.run(watchTransaction);
sagaMiddleware.run(watchNotification);
sagaMiddleware.run(watchCms);
sagaMiddleware.run(watchAffiliate);
sagaMiddleware.run(watchSwap);
export default store;
