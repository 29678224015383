import {
    USER_INFO,
    USER_INFO_SUCCESS,
    USER_INFO_FAIL,
  } from 'store/actionLabels';

  const initialState = {
    isLoading: false,
    userInfoData: {},
  };

  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case USER_INFO:
        return {
          ...state,
          isLoading: true,
        };
      case USER_INFO_SUCCESS:
        return {
          ...state,
          userInfoData: payload,
          isLoading: false,
        };
      case USER_INFO_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isLoading: false,
          content: {},
        };
      }
      default:
        return state;
    }
  };
