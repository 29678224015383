import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Modal } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import * as actions from 'store/actions';

import { CustomInput, CustomModalHeader } from 'components';
import { changePasswordValidator } from 'validations/password';
import { handleToastfunction } from 'utils';
import { useTranslation } from 'react-i18next';

function ChangePassword({ closeModal }) {
  const { Theme } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createInitialValue = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangePassword = async (values, resetForm) => {
    if (values?.confirmPassword === values?.newPassword) {
      try {
        dispatch(actions.forgotAuthenticatorAction({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          close: closeModal,
        }));
        resetForm();
      } catch (error) {
        handleToastfunction({
          status: 'error',
          title: 'Somethings went wrong, please try again later',
        });
      }
    } else {
      handleToastfunction({ status: 'error', title: 'Password and Confirm Password Mismatched!' });
    }
  };

  return (
    <Modal isOpen centered data-theme={Theme} id="view_modal">
      <CustomModalHeader title={t('Change Password')} theme={Theme} closeHandle={closeModal} />
      <div className="hiddenScroll" id="custom_target">
        <Formik
          initialValues={createInitialValue}
          validationSchema={changePasswordValidator(t)}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            handleChangePassword(values, resetForm);
          }}
        >
          <Form id="change_password_form">
            <div className="form_wrapper pt-4 change_password_form_wrapper">
              <Field
                component={CustomInput}
                className="mb-4 "
                name="oldPassword"
                type={showOldPassword ? 'text' : 'password'}
                autoComplete="off"
                setShowPassword={setShowOldPassword}
                showPassword={showOldPassword}
                showEyeIcon
                label={t('Old Password')}
                withOutLabel
              />
              <Field
                component={CustomInput}
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                autoComplete="off"
                setShowPassword={setShowNewPassword}
                showPassword={showNewPassword}
                showEyeIcon
                label={t('New Password')}
                withOutLabel
              />
              <Field
                component={CustomInput}
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                autoComplete="off"
                setShowPassword={setShowConfirmPassword}
                showPassword={showConfirmPassword}
                showEyeIcon
                label={t('Confirm Password')}
                withOutLabel
              />
            </div>

            <div className="mt-3 text-end">
              <button
                className="btn btn-primary w-sm waves-effect waves-light w-100 py-2 primary-button-color"
                type="submit"
              >
                {t('Change Password')}
              </button>
            </div>
          </Form>
        </Formik>

        <div className="Reset_Para mt-3">
          <p>1. {t('At least 8 characters with atleast 1 uppercase and 1 lowercase letters.')}</p>
          <p>2. {t('A mixture of letters and Numbers.')}</p>
          <p>3. {t('Inclusion of at least one special character.')}</p>
          {/* <p>4. Change password in every 90 Days to increase security.</p> */}
        </div>
        <Alert color="info">{t('NOTE : Change password in every 90 Days to increase security')}</Alert>
      </div>
    </Modal>
  );
}

export default ChangePassword;
