import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import { handleToastfunction } from 'utils';
import { getSwapListSuccess, getSwapListFail } from 'store/actions';
import { GET_SWAP_LIST_URL } from 'apis';

export function* getSwapList({ payload }) {
  const {
    itemsPerPage,
    startIndex,
    search,
    userType,
    startDate,
    endDate,
    sortby,
    sortValue,
    status,
  } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (status) {
    params.status = status;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_SWAP_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getSwapListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getSwapListFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
