import * as actionLabels from '../../actionLabels';

const initialState = {
  swapTransactionList: [],
  isLoading: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
      case actionLabels.GET_SWAP_TRANSC_LIST:
        return {
          ...state,
          isLoading: true,
        };
      case actionLabels.GET_SWAP_TRANSC_LIST_SUCCESS:
        return {
          ...state,
          swapTransactionList: payload,
          isLoading: false,
        };
      case actionLabels.GET_SWAP_TRANSC_LIST_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isLoading: false,
          content: {},
        };
      }
      default:
        return state;
    }
  };
