export const GET_TOKEN_GENERATION_FEE = 'GET_TOKEN_GENERATION_FEE';
export const GET_TOKEN_GENERATION_FEE_FAIL = 'GET_TOKEN_GENERATION_FEE_FAIL';
export const GET_TOKEN_GENERATION_FEE_SUCCESS = 'GET_TOKEN_GENERATION_FEE_SUCCESS';

export const GET_PLATFORM_FEE_LIST = 'GET_PLATFORM_FEE_LIST';
export const GET_PLATFORM_FEE_LIST_FAIL = 'GET_PLATFORM_FEE_LIST_FAIL';
export const GET_PLATFORM_FEE_LIST_SUCCESS = 'GET_PLATFORM_FEE_LIST_SUCCESS';

export const GET_LISTING_FEE_LIST = 'GET_LISTING_FEE_LIST';
export const GET_LISTING_FEE_LIST_FAIL = 'GET_LISTING_FEE_LIST_FAIL';
export const GET_LISTING_FEE_LIST_SUCCESS = 'GET_LISTING_FEE_LIST_SUCCESS';

export const TRANSACTION_EXPORT_LIST = 'TRANSACTION_EXPORT_LIST';
export const TRANSACTION_EXPORT_LIST_FAIL = 'TRANSACTION_EXPORT_LIST_FAIL';
export const TRANSACTION_EXPORT_LIST_SUCCESS = 'TRANSACTION_EXPORT_LIST_SUCCESS';
export const CLEAR_EXPORT_DATA = 'CLEAR_EXPORT_DATA';

export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';

export const EXPORT_LISTING_FEE = 'EXPORT_LISTING_FEE';
export const EXPORT_LISTING_FEE_FAIL = 'EXPORT_LISTING_FEE_FAIL';
export const EXPORT_LISTING_FEE_SUCCESS = 'EXPORT_LISTING_FEE_SUCCESS';

export const EXPORT_TOKENGENERATION_FEE = 'EXPORT_TOKENGENERATION_FEE';
export const EXPORT_TOKENGENERATION_FEE_FAIL = 'EXPORT_TOKENGENERATION_FEE_FAIL';
export const EXPORT_TOKENGENERATION_FEE_SUCCESS = 'EXPORT_TOKENGENERATION_FEE_SUCCESS';

export const EXPORT_PLATFORM_FEE = 'EXPORT_PLATFORM_FEE';
export const EXPORT_PLATFORM_FEE_FAIL = 'EXPORT_PLATFORM_FEE_FAIL';
export const EXPORT_PLATFORM_FEE_SUCCESS = 'EXPORT_PLATFORM_FEE_SUCCESS';
