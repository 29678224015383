/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  userListing: {},
  userOverview: {},
  userExportList: {},
  userDetails: {},
  investorDetails: {},
  creatorDetails: {},
  blockUser: {},
  suspendUser: {},
  isLoading: false,
  creatorLoading: false,
  investorLoading: false,
  suspendLoading: false,
  blockLoading: false,
  isSuspensionComplete: false,
  isBlockedComplete: false,
  userLoading: false,
  suspendBlockList: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.USER_LIST:
      return {
        ...state,
        userLoading: true,
      };
    case actionLabels.USER_LIST_SUCCESS:
      return {
        ...state,
        userListing: payload,
        userLoading: false,
      };
    case actionLabels.USER_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        userLoading: false,
        content: {},
      };
    }
    case actionLabels.USER_OVERVIEW:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.USER_OVERVIEW_SUCCESS:
      return {
        ...state,
        userOverview: payload,
        isLoading: false,
      };
    case actionLabels.USER_OVERVIEW_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.USER_EXPORT_LIST:
      return {
        ...state,
        userLoading: true,
      };
    case actionLabels.USER_EXPORT_LIST_SUCCESS:
      return {
        ...state,
        userExportList: payload,
        userLoading: false,
      };
    case actionLabels.USER_EXPORT_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        userLoading: false,
        content: {},
      };
    }
    case actionLabels.USER_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload,
        isLoading: false,
      };
    case actionLabels.USER_DETAILS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.INVESTOR_DETAILS:
      return {
        ...state,
        investorLoading: true,
      };
    case actionLabels.INVESTOR_DETAILS_SUCCESS:
      return {
        ...state,
        investorDetails: payload,
        investorLoading: false,
      };
    case actionLabels.INVESTOR_DETAILS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        investorLoading: false,
        content: {},
      };
    }
    case actionLabels.CREATOR_DETAILS:
      return {
        ...state,
        creatorLoading: true,
      };
    case actionLabels.CREATOR_DETAILS_SUCCESS:
      return {
        ...state,
        creatorDetails: payload,
        creatorLoading: false,
      };
    case actionLabels.CREATOR_DETAILS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        creatorLoading: false,
        content: {},
      };
    }

    case actionLabels.BLOCK_USER:
      return {
        ...state,
        isLoading: true,
        isBlockedComplete: false,
      };
    case actionLabels.BLOCK_USER_SUCCESS:
      return {
        ...state,
        blockUser: payload,
        isLoading: false,
        isBlockedComplete: true,
      };
    case actionLabels.BLOCK_USER_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
        isBlockedComplete: false,
      };
    }
    case actionLabels.SUSPEND_USER:
      return {
        ...state,
        isLoading: true,
        isSuspensionComplete: false,
      };
    case actionLabels.SUSPEND_USER_SUCCESS:
      return {
        ...state,
        suspendUser: payload,
        isLoading: false,
        isSuspensionComplete: true,
      };
    case actionLabels.SUSPEND_USER_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
        isSuspensionComplete: false,
      };
    }
    case actionLabels.SUSPEND_BLOCK_USER_LIST:
      return {
        ...state,
        userLoading: true,
      };
    case actionLabels.SUSPEND_BLOCK_USER_LIST_SUCCESS:
      return {
        ...state,
        userListing: payload,
        userLoading: false,
      };
    case actionLabels.SUSPEND_BLOCK_USER_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        userLoading: false,
        content: {},
      };
    }
    case actionLabels.EXP_BLOCK_SUSPEND_LIST:
      return {
        ...state,
        userLoading: true,
      };
    case actionLabels.EXP_BLOCK_SUSPEND_LIST_SUCCESS:
      return {
        ...state,
        userExportList: payload,
        userLoading: false,
      };
    case actionLabels.EXP_BLOCK_SUSPEND_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        userLoading: false,
        content: {},
      };
    }

    default:
      return state;
  }
};
