/* eslint-disable */
import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDisconnect } from 'wagmi';
import { useTranslation } from 'react-i18next';
import { ConnectButton } from '@rainbow-me/rainbowkit';

// static imports
import './index.css';
import RenderIf from './RenderIf';
import ChangePassword from 'views/user/profle/ChangePassword';
import user4 from 'assets/images/avatar.jpg';
import Language from './language/Language';
import Notification from './notification/Notification';
import DeleteModal from './Modal/DeleteModal';
import { DROPDOWN_ICON } from 'assets/images';

function ProfileMenu() {
  const { disconnect } = useDisconnect()
  const {t} = useTranslation();
  const [passwordModal, setPasswordModal] = useState(false);
  const [logout, setLogout] = useState(false);
  const [menu, setMenu] = useState(false);

  const handlePassword = () => {
    setPasswordModal(false);
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    disconnect();
    window.location.reload();
  }

  return (
    <div className="wrapper">
      <ConnectButton label={t('Connect Wallet')} />
      <Notification />
      <Language />

      <Link to="/profile">
        <img
          className="rounded-circle header-profile-user"
          src={user4}
          alt="profile"
        />
        <span className="d-none d-xl-inline-block ms-1 fw-medium text_primary profile_text">
          {t("Admin")}
        </span>
      </Link>

      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect ps-2 mb-2"
          id="page_headername_dropdown"
          tag="button"
        >
          <img src={DROPDOWN_ICON} alt="dropdown" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" id="logout_drop">
          <div
            type="button"
            className="dropdown-item"
            onClick={() => setPasswordModal(true)}
            id="change_password"
          >
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted" />
           {t("Change Password")}
          </div>

          <div
            type="button"
            className="dropdown-item"
            onClick={() => setLogout(true)}
            id="logout_dropdown"
          >
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted" />
            {t("Logout")}
          </div>
        </DropdownMenu>
      </Dropdown>
      <RenderIf render={logout}>
        <DeleteModal
          show={logout}
          title={t("Are you sure you want to logout?")}
          close={() => setLogout(false)}
          confirm={handleLogout}
        />
      </RenderIf>
      {passwordModal && <ChangePassword closeModal={handlePassword} />}
    </div>
  );
}

export default ProfileMenu;
