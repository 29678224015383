const enTranslation = {
  Dashboard: 'Dashboard',
  Overview: 'Overview',
  'Total Investments': 'Total Investments',
  'Total Users': 'Total Users',
  'New User': 'New User',
  'Total Projects': 'Total Projects',
  'Pending Project for Approval': 'Pending Project for Approval',
  Statistics: 'Statistics',
  'Top Project Investment Summary': 'Top Project Investment Summary',
  Creator: 'Creator',
  Investor: 'Investor',
  'Total amount to be rasied': 'Total amount to be rasied',
  'Fund raised': 'Fund raised',
  'View all': 'View all',
  'Sr. no': 'Sr. no',
  Actions: 'Actions',
  'Project Tracking': 'Project Tracking',
  'New Project Request': 'New Project Request',
  Open: 'Open',
  Closed: 'Closed',
  Completed: 'Completed',
  Failed: 'Failed',
  'Creator Name': 'Creator Name',
  'Project name': 'Project name',
  'Project Creation Date': 'Project Creation Date',
  'Approve project': 'Approve project',
  'Reject project': 'Reject project',
  View: 'View',
  Approve: 'Approve',
  Reject: 'Reject',
  Search: 'Search...',
  'Start date': 'Start date',
  'End date': 'End date',
  'Rows per page': 'Rows per page',
  Users: 'Users',
  'Active Creator': 'Active Creator',
  'Active Investors': 'Active Investors',
  'Full Name': 'Full Name',
  Prev: 'Prev',
  Next: 'Next',
  Export: 'Export',
  'Sort by: User': 'Sort by: User',
  'All users': 'All users',
  Transactions: 'Transactions',
  Admin: 'Admin',
  User: 'User',
  'Platform Fee': 'Platform Fee',
  'Listing Fee Transaction Hash': 'Listing Fee Transaction Hash',
  'Time Stamp': 'Time Stamp',
  'Transaction Type': 'Transaction Type',
  Amount: 'Amount',
  'Listing Fee ': 'Listing Fee',
  'Token Generation Fee ': 'Token Generation Fee',
  'Platform Percentage': 'Platform Percentage',
  Update: 'Update',
  'Enter Matic': 'Enter Matic',
  'Enter Fee': 'Enter Fee',
  'Date and Time': 'Date and Time',
  'Old Percentage': 'Old Percentage',
  'New Percentage': 'New Percentage',
  'Old Listing Fee': 'Old Listing Fee',
  'New Listing Fee': 'New Listing Fee',
  'Old Token Generation Fee': 'Old Token Generation Fee',
  'New Token Generation Fee': 'New Token Generation Fee',
  'Project Name': 'Project Name',
  'Project testing for notification': 'Project testing for notification',
  'Project Description': 'Project Description',
  'Description here': 'Description here',
  'Project Website': 'Project Website',
  'Soft Cap': 'Soft Cap',
  'Hard Cap': 'Hard Cap',
  'Social Media Links': 'Social Media Links',
  Duration: 'Duration',
  Days: 'Days',
  'Team Details': 'Team Details',
  'Team Member Name': 'Team Member Name',
  'Member 1': 'Member 1',
  'Team Member Role': 'Team Member Role',
  'Team Member Picture': 'Team Member Picture',
  'Other Supporting Documents': 'Other Supporting Documents',
  'Token Details': 'Token Details',
  'Token Address': 'Token Address',
  'Token Supply': 'Token Supply',
  'Token Name': 'Token Name',
  'Token Price': 'Token Price',
  'Token Symbol': 'Token Symbol',
  'Token Distribution Plan': 'Token Distribution Plan',
  'Road Map': 'Road Map',
  Date: 'Date',
  Description: 'Description',
  'Other Documents': 'Other Documents',
  Edit: 'Edit',
  Projects: 'Projects',
  'Listed Projects': 'Listed Projects',
  'There are no records to display': 'There are no records to display',
  Suspend: 'Suspend',
  Block: 'Block',
  'Admin Profile': 'Admin Profile',
  'Email Id': 'Email Id',
  'Phone Number': 'Phone Number',
  Cancel: 'Cancel',
  Save: 'Save',
  Upload: 'Upload',
  'Project Details': 'Project Details',
  'User Name': 'User Name',
  Feedback: 'Feedback',
  'Platform Variable': 'Platform Variable',
  'Project Pending to List': 'Project Pending to List',
  Deleted: 'Deleted',
  'Listed Date': 'Listed Date',
  'Blockchain Status': 'Blockchain Status',
  'Transaction Hash': 'Transaction Hash',
  'Total Amount': 'Total Amount',
  'Old Variable': 'Old Variable',
  'New Variable': 'New Variable',
  From: 'From',
  To: 'To',
  'ICO End Date': 'ICO End Date',
  'Funding Status': 'Funding Status',
  'Approval Date': 'Approval Date',
  'Creation Date': 'Creation Date',
  Status: 'Status',
  'Transaction Hash & Timestamp': 'Transaction Hash & Timestamp',
  'Platform percentage hash': 'Platform percentage hash',
  'Number of Investments': 'Number of Investments',
  pending: 'pending',
  'Amount Raised': 'Amount Raised',
  'Soft cap fail': 'Soft cap fail',
  'In Progress': 'In Progress',
  'Min. Investment': 'Min. Investment',
  Progress: 'Progress',
  Participants: 'Participants',
  OK: 'OK',
  'Change Password': 'Change Password',
  Logout: 'Logout',
  'Connect Wallet': 'Connect Wallet',
  'Are you sure you want to approve ?': 'Are you sure you want to approve ?',
  'Platform Transaction Hash Code': 'Platform Transaction Hash Code',
  Timestamp: 'Timestamp',
  'Are you sure you want to logout ?': 'Are you sure you want to logout?',
  'Log In': 'Log In',
  Notification: 'Notification',
  'Email address': 'Email address',
  'User Type': 'User Type',
  'Clear All': 'Clear All',
  'Sort by': 'Sort by',
  'Old to New': 'Old to New',
  'New to Old': 'New to Old',
  Reactivate: 'Reactivate',
  Unblock: 'Unblock',
  'Terms of Use': 'Terms of Use',
  'Privacy Policy': 'Privacy Policy',
  'About Us': 'About Us',
  'Live Projects': 'Live Projects',
  'Completed Projects': 'Completed Projects',
  'Rejected Projects': 'Rejected Projects',
  'Failed Projects': 'Failed Projects',
  'Deleted Projects': 'Deleted Projects',
  'Are you sure you want to block user ?': 'Are you sure you want to block user ?',
  'Enter Description': 'Enter Description',
  'Blocked User': 'Blocked User',
  'Suspended User': 'Suspended User',
  Email: 'Email',
  'Registration Timestamp': 'Registration Timestamp',

  'Project Name is required': 'Project Name is required',
  'Minimum 2 Characters': 'Minimum 2 Characters',
  'Maximum 15 Characters': 'Maximum 15 Characters',
  'Project description is required': 'Project description is required',
  'Must have atLeast 2 characters': 'Must have atLeast 2 characters',
  'Cannot exceed 100 characters': 'Cannot exceed 100 characters',
  'Project Duration is required': 'Project Duration is required',
  'Soft cap must be a number': 'Soft cap must be a number',
  'Soft cap must be a positive number': 'Soft cap must be a positive number',
  'Soft cap is required': 'Soft cap is required',
  'Hard cap must be a number': 'Hard cap must be a number',
  'Hard cap must be a positive number': 'Hard cap must be a positive number',
  'Hard cap is required': 'Hard cap is required',
  'Hard cap must be greater than soft cap': 'Hard cap must be greater than soft cap',
  'Token price is required': 'Token price is required',
  'Token price must be a positive number': 'Token price must be a positive number',
  'Lockup period is required': 'Lockup period is required',
  'Invalid URL': 'Invalid URL',
  'Project Website is required': 'Project Website is required',
  'Min Investment must be a number': 'Min Investment must be a number',
  'Min Investment must be a positive number': 'Min Investment must be a positive number',
  'Min Investment is required': 'Min Investment is required',
  'Min Investment must be less than hard cap': 'Min Investment must be less than hard cap',
  'Distribution plan is required': 'Distribution plan is required',
  'Old Password': 'Old Password',
  'New Password': 'New Password',
  'Confirm Password': 'Confirm Password',
  'At least 8 characters with atleast 1 uppercase and 1 lowercase letters.':
    'At least 8 characters with atleast 1 uppercase and 1 lowercase letters.',
  'A mixture of letters and Numbers.': 'A mixture of letters and Numbers.',
  'Inclusion of at least one special character.': 'Inclusion of at least one special character.',
  'NOTE : Change password in every 90 Days to increase security':
    'NOTE : Change password in every 90 Days to increase security',
  'Old Password is required': 'Old Password is required',
  'New Password is required': 'New Password is required',
  'Confirm Password is required': 'Confirm Password is required',
  'It must have atleast 8 characters, 1 Uppercase, 1 Lowercase, 1 digit & 1 special character':
    '「It must have atleast 8 characters, 1 Uppercase, 1 Lowercase, 1 digit & 1 special character',
  'Passwords must match': 'Passwords must match',
  Back: 'Back',
  ReadAll: 'Read All',
  PleaseConnectTheWallet: 'Please connect the wallet',
  ErrorDeletingProject: 'Error deleting project',
  TransactionRejectedByUser: 'Transaction rejected by user',
  ProjectDeletedSuccessfully: 'Project Deleted Successfully',
  EnterValidFeeAmount: 'Enter valid fee amount',
  ErrorUpdatingTgeFee: 'Error updating tge fee',
  FeeUpdatedSuccessfully: 'Fee Updated Successfully',
  ErrorUpdatingTokenFee: 'Error updating token fee',
  ErrorUpdatingPlatformFee: 'Error updating platform fee',
  onlyWholeNumbersAreAllowed: 'only whole numbers are allowed',
  PleaseConnectWithYourRegisteredWallet: 'Please connect with your registered wallet.',
  'Cannot exceed 200 characters': 'Cannot exceed 200 characters',
  'Are you sure you want to delete project?': 'Are you sure you want to delete project?',
  RoadMapDocuments: 'Road Map Documents',
  Month: 'Month',
  Months: 'Months',
  Year: 'Year',
  None: 'None',
  LockupPeriod: 'Lockup Period',
  Twitter: 'Twitter',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  Telegram: 'Telegram',
  ProjectApprovedSuccessfully: 'Project Approved Successfully !',
  ProjectIsNotApproved: 'Project is not approved',
  WalletAddress: 'Wallet Address',
  ErrorUpdatingListingFee: 'Error Updating Listing Fee',
  Claim: 'Claim',
  Invested: 'Invested',
  affiliateName: 'Affiliate Name',
  affiliateList: 'Affiliate List',
  affiliateDetails: 'Affiliate Details',
  Percentage: 'Percentage',
  referralCode: 'Referral Code',
  Wallet: 'Wallet',
  investorDetail: 'Investor Detail',
  creatorDetail: 'Creator Detail',
  affiliateShare: 'Affiliate Share',
  investorName: 'Investor Name',
  amountInvested: 'Amount Invested',
  affiliateIvestment: 'Affiliate Investment',
  Affiliate: 'Affiliate',
  NoNotification: 'No Notification',
  Send: 'Send',
  descriptionIsRequired: 'Description is required',
  'Description must be at most 250 characters': 'Description must be at most 250 characters',
  projectDetail: 'Project Detail',
  No: 'No',
  Yes: 'Yes',
  youtubeLink: 'Youtube Link',
  'Sort by: Status': 'Sort by: Status',
  Exchange: 'Exchange',
  fromToken: 'From Token',
  fromTokenAmount: 'From Token Amount',
  toToken: 'To Token',
  toTokenAmount: 'To Token Amount',
  transactionType: 'Transaction Type',
  transactionAddress: 'Transaction Address',
  fromNetwork: 'From Network',
  toNetwork: 'To Network',
  Commission: 'Commission (%)',
  PartnersFee: 'Partners Fee',
  SwapList: 'Swap List',
  Swap: ' Swap',
};

export default enTranslation;
