export const GET_AFFILIATE_LIST = 'GET_AFFILIATE_LIST';
export const GET_AFFILIATE_LIST_FAIL = 'GET_AFFILIATE_LIST_FAIL';
export const GET_AFFILIATE_LIST_SUCCESS = 'GET_AFFILIATE_LIST_SUCCESS';

export const GET_AFFILIATE_INFO = 'GET_AFFILIATE_INFO';
export const GET_AFFILIATE_INFO_FAIL = 'GET_AFFILIATE_INFO_FAIL';
export const GET_AFFILIATE_INFO_SUCCESS = 'GET_AFFILIATE_INFO_SUCCESS';

export const GET_AFFILIATE_DETAILS = 'GET_AFFILIATE_DETAILS';
export const GET_AFFILIATE_DETAILS_FAIL = 'GET_AFFILIATE_DETAILS_FAIL';
export const GET_AFFILIATE_DETAILS_SUCCESS = 'GET_AFFILIATE_DETAILS_SUCCESS';

export const GET_AFFILIATE_INVESTMENT = 'GET_AFFILIATE_INVESTMENT';
export const GET_AFFILIATE_INVESTMENT_FAIL = 'GET_AFFILIATE_INVESTMENT_FAIL';
export const GET_AFFILIATE_INVESTMENT_SUCCESS = 'GET_AFFILIATE_INVESTMENT_SUCCESS';

export const EXPORT_AFFILIATE_INVESTMENT = 'EXPORT_AFFILIATE_INVESTMENT';
export const EXPORT_AFFILIATE_INVESTMENT_FAIL = 'EXPORT_AFFILIATE_INVESTMENT_FAIL';
export const EXPORT_AFFILIATE_INVESTMENT_SUCCESS = 'EXPORT_AFFILIATE_INVESTMENT_SUCCESS';
