import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './notification.css';
import { SOCKET_EVENTS, socket, initiatesocket } from 'SocketConfig';
import { getNotificationSagaSuccess } from 'store/actions';
import { CryptoSpinner } from 'components';
import { UNREAD_NOTIFICATION } from 'assets/images';

function Notification() {
  const { isLoading, notificationsList } = useSelector((state) => state.notification);
  const { userInfoData } = useSelector((state) => state.profile);
  const { authToken } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const [expandNotifications, setExpandNotifications] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchNotification(true);
  }, [authToken]);

  useEffect(() => {
    const language = userInfoData ? userInfoData?.language === 'jp' ? 'ja' : userInfoData?.language : '';
    setSelectedLanguage(language);
  }, [t, userInfoData]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchNotification = (isNew = false) => {
    initiatesocket(() => {
      socket.emit(SOCKET_EVENTS.JOIN, { startIndex: isNew ? 1 : page });
      socket.on(SOCKET_EVENTS.NOTIFICATION, (data) => {
        if (data) {
          if (isNew) {
            dispatch(getNotificationSagaSuccess(data));
          } else {
            dispatch(getNotificationSagaSuccess({
              ...notificationsList,
              data: {
                ...notificationsList.data,
                data: [...notificationsList?.data?.data, ...data?.data?.data],
              },
              hasMore: data?.data?.length < data?.totalItems, // Check if more pages are available
            }));
          }
          setPage(page + 1);
          setHasMore(data?.data?.data?.length < data?.data?.totalItems);
        }
      });
    });
  };

  const handleClearNotifications = () => {
    socket.emit(SOCKET_EVENTS.DELETE, { isRemoveAll: true });
  };

  const handleReadNotifications = () => {
    socket.emit(SOCKET_EVENTS.MARK_ALL_AS_READ, { isMarkAll: true }, (data) => {
    });
  };

  const handleReadSingleNotification = (id) => {
    socket.emit(SOCKET_EVENTS.MARK_AS_READ, { _notification: id });
  };

  const fetchMoreData = () => {
    if (page < notificationsList?.data?.totalPage) {
      setPage(page + 1);
      fetchNotification();
    } else {
      setHasMore(false); // No more data to load
    }
  };

  return (
    <div className="notfication_sidebar">
      <Button onClick={handleShow} className="notification_btn" style={{ position: 'relative' }}>
        <i className="uil uil-bell align-middle ms-4 text-muted" />
        {notificationsList?.unreadCounts > 0 && (
          <p className="notification_count">
            {notificationsList?.unreadCounts}
          </p>
        )}
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <div className="notification_body">
          <div className="notification_heading back_button">
            <i className="uil uil-arrow-left align-middle text-muted cursor-pointer" onClick={handleClose} />
            {t('Notification')}
          </div>
          <div className="notification_header">
            {notificationsList?.data?.data?.length !== 0 && (
              <div className="clear cursor-pointer my-3" onClick={() => handleReadNotifications()}>{t('ReadAll')}</div>
            )}
            {notificationsList?.data?.data?.length !== 0 && (
              <div className="clear cursor-pointer my-3" onClick={() => handleClearNotifications()}>{t('Clear All')}</div>
            )}
          </div>
          <div className="notifications">
            {notificationsList?.data?.data?.length ? (
              <InfiniteScroll
                dataLength={notificationsList?.data?.data?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<CryptoSpinner />}
                height={600}
                className="pe-3 pb-2"
              >
                {notificationsList?.data?.data?.map((item) => {
                  const description = item?.description?.[selectedLanguage];
                  const isLongDescription = description?.length > 200;
                  return (
                    <div className="notification_msg cp" key={item?._id}>
                      <div className={`${!item?.isRead ? 'unread_notification' : ''} notification_container`}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="message_head">{item?.title?.[selectedLanguage]}</div>
                          {item?.isRead === false && <img src={UNREAD_NOTIFICATION} className=" unread_img" />}
                        </div>
                        <div className="message_content">
                          {expandNotifications[item?._id] || !isLongDescription
                            ? description
                            : `${description.slice(0, 500)}...`}{' '}
                          {isLongDescription && (
                            <span
                              className="click"
                              onClick={() => setExpandNotifications({
                                ...expandNotifications,
                                [item?._id]: !expandNotifications[item?._id],
                              })}
                            >
                              {expandNotifications[item?._id]
                                ? t('ShowLess')
                                : t('Show more')}
                            </span>
                          )}
                        </div>
                        <div className="time">
                          {moment(item?.createdAt).startOf('min').fromNow() === 'an hour ago'
                            ? 'An hour ago'
                            : moment(item?.createdAt).startOf('min').fromNow()}
                        </div>
                        {!item?.isRead && (
                          <div
                            className="clear cursor-pointer"
                            onClick={() => handleReadSingleNotification(item?._id)}
                          >
                            {t('Mark as read')}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            ) : isLoading ? (
              <CryptoSpinner className="position-relative" />
            ) : (
              <div className="text-center">
                {t('NoNotification')}
              </div>
            )}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
}

export default Notification;
