/*eslint-disable */
import { Dropdown } from "react-bootstrap";
import "./language.css";
import {
  DROPDOWN_ICON
} from 'assets/images';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { languageChooseSaga } from "store/actions";
import * as actions from "../../store/actions"

const Language = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const { userInfoData } = useSelector((state) => state.profile);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleLanguageChange = (label, value) => {
    dispatch(actions.userInfoSagaSuccess({
      ...userInfoData,
      language: value,
    }));
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
    setSelectedLanguage(label);
  };

  useEffect(() => {
    const language = localStorage.getItem("language") || 'en';
    setSelectedLanguage(language === 'en' ? "English" : "日本語");
  }, []);

  useEffect(() => {
    if (selectedLanguage !== "") {
      dispatch(languageChooseSaga({
        lang: selectedLanguage === "English" ? "en" : "ja"
      }));
    }
  }, [selectedLanguage]);

  return (
    <Dropdown className="language_dd" >
      <Dropdown.Toggle id="dropdown-basic" className="dropdown_text">
        {selectedLanguage}
        <img src={DROPDOWN_ICON} alt="dropdown" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item key="support">
          <div className="position-relative">
            <div className="option_body">
              <div
                className="profile_option"
                onClick={() => handleLanguageChange("English", "en")}
              >
                <div>English</div>
              </div>
              <div
                className="profile_option"
                onClick={() => handleLanguageChange("日本語", "jp")}
              >
                <div>日本語</div>
              </div>
            </div>
            {/* <img src={SUPPORT_ARROW} alt="arrow" className="dropdown_arrow" /> */}
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Language;
