import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  dashboardOverviewSagaSuccess,
  dashboardOverviewSagaFail,
  dashboardStatisticsSagaFail,
  dashboardStatisticsSagaSuccess,
  dashboardGraphSagaSuccess,
  dashboardGraphSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import { DASHBOARD_GRAPH_URL, DASHBOARD_STATISTICS_URL, DASHBOARD_OVERVIEW_URL } from 'apis';

export function* dashboardOverviewSaga({ payload }) {
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${DASHBOARD_OVERVIEW_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(dashboardOverviewSagaSuccess({ overviewData: data }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(dashboardOverviewSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
export function* dashboardStatisticsSaga({ payload }) {
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${DASHBOARD_STATISTICS_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(dashboardStatisticsSagaSuccess({ statisticsData: data }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(dashboardStatisticsSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* dashboardGraphSaga({ payload }) {
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${DASHBOARD_GRAPH_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(dashboardGraphSagaSuccess({ data }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(dashboardGraphSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
