import * as actionLabels from '../../actionLabels';

const initialState = {
    platformHistoryData: {},
    platformVariable: {},
    isPlatformLoading: false,
    platformListLoading: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
      case actionLabels.PLATFORM_HISTORY:
        return {
          ...state,
          platformListLoading: true,
        };
      case actionLabels.PLATFORM_HISTORY_SUCCESS:
        return {
          ...state,
          platformHistoryData: payload,
          platformListLoading: false,
        };
      case actionLabels.PLATFORM_HISTORY_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          platformListLoading: false,
          content: {},
        };
      }
      case actionLabels.GET_PLATFORM_VARIABLE:
        return {
          ...state,
          isPlatformLoading: true,
        };
      case actionLabels.GET_PLATFORM_VARIABLE_SUCCESS:
        return {
          ...state,
          platformVariable: payload,
          isPlatformLoading: false,
        };
      case actionLabels.GET_PLATFORM_VARIABLE_FAIL: {
        return {
          ...state,
          errorMsg: payload,
          isPlatformLoading: false,
          content: {},
        };
      }
      default:
        return state;
    }
  };
