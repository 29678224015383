/*eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from "./constants/enTranslation"
import jpTranslations from "./constants/jpTranslation"

// Import translations
const resources = {
  en: {
    translation: enTranslations,
  },
  jp: {
    translation: jpTranslations,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;