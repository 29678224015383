import * as actionLabels from '../../actionLabels';

export const updateCmsSaga = payload => ({
  type: actionLabels.UPDATE_CMS,
  payload,
});

export const updateCmsSagaFail = payload => ({
  type: actionLabels.UPDATE_CMS_FAIL,
  payload,
});

export const updateCmsSagaSuccess = payload => ({
  type: actionLabels.UPDATE_CMS_SUCCESS,
  payload,
});

export const getCmsDetailSaga = payload => ({
  type: actionLabels.GET_CMS_DETAILS,
  payload,
});

export const getCmsDetailSagaFail = payload => ({
  type: actionLabels.GET_CMS_DETAILS_FAIL,
  payload,
});

export const getCmsDetailSagaSuccess = payload => ({
  type: actionLabels.GET_CMS_DETAILS_SUCCESS,
  payload,
});
