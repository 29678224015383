import axios from 'axios';
import { GlobalLogout } from 'utils';

export const axiosMain = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setBaseUrl = (baseUrl) => {
  axiosMain.defaults.baseURL = baseUrl;
};

// axiosMain.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 403 || error.response.status === 401) {
//       GlobalLogout(localStorage.getItem('theme'));
//       return Promise.reject(error);
//     }
//     return Promise.reject(error);
//   },
// );
