import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  clearNotificationSagaSuccess,
  clearNotificationSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import { CLEAR_NOTIFICATION_URL } from 'apis';

export function* clearNotificationSaga({ payload }) {
  const { flag } = payload;
  const params = {
    isRemoveAll: flag,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${CLEAR_NOTIFICATION_URL}`,
    payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(clearNotificationSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(clearNotificationSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}
