import * as actionLabels from '../../actionLabels';

export const getNotificationSaga = payload => ({
  type: actionLabels.GET_NOTIFICATION,
  payload,
});

export const getNotificationSagaFail = payload => ({
  type: actionLabels.GET_NOTIFICATION_FAIL,
  payload,
});

export const getNotificationSagaSuccess = payload => ({
  type: actionLabels.GET_NOTIFICATION_SUCCESS,
  payload,
});

export const clearNotificationSaga = payload => ({
  type: actionLabels.CLEAR_NOTIFICATION,
  payload,
});

export const clearNotificationSagaFail = payload => ({
  type: actionLabels.CLEAR_NOTIFICATION_FAIL,
  payload,
});

export const clearNotificationSagaSuccess = payload => ({
  type: actionLabels.CLEAR_NOTIFICATION_SUCCESS,
  payload,
});
