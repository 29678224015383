import { toast } from 'react-toastify';
import ToastComponent from 'components/ToastComponent';
import toasterclose from '../assets/images/toasterclose.svg';

export const compareArray = (a1, a2) => {
  let changed = false;
  if (a1.length !== a2.length) return true;
  if (!a1.length) return false;
  a1.forEach((element, index) => {
    if (JSON.stringify(element) !== JSON.stringify(a2[index])) {
      changed = true;
    }
  });
  return changed;
};

export const deleteObjKeys = (arr, obj) => {
  arr.forEach(ele => {
    delete obj[ele];
  });
  return obj;
};
export const handleResetSearchRange = (setFilterValue, filterValue, setCurrentPage) => {
  setCurrentPage(1);
  setFilterValue({
    ...filterValue,
    startDate: '',
    endDate: '',
  });
};

export const GlobalLogout = async Theme => {
  sessionStorage.clear();
  localStorage.clear();
  localStorage.setItem('theme', Theme);
  window.location.reload();
};

function CloseButton({ closeToast }) {
  return (
    <div className="wrapper d-flex align-items-start justify-content-start">
      <img src={toasterclose} onClick={closeToast} alt="test" className="cp" />
    </div>
  );
}

export const handleToastfunction = ({ status, title, duration, subTitle, action = false }) => {
  toast(
    <ToastComponent
      status={status}
      title={title || 'Something went wrong! Please try again later.'}
      subTitle={subTitle}
      action={action}
    />,
    {
      position: 'top-right',
      autoClose: duration || 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      closeButton: <CloseButton />,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    },
  );
};

export const removeEmptyValues = obj => {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (newObj[key] === '') {
      delete newObj[key];
    }
  }
  return newObj;
};
