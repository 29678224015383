/* eslint-disable */
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { userRoutes } from '../../routes';
import { useEffect } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const MainContainer = () => {
  const routes = (() => userRoutes)();
  const { disconnect } = useDisconnect();
  const { t } = useTranslation();
  const { userInfoData } = useSelector(state => state.profile);
  const { isConnected, address } = useAccount();
  const { Theme, authToken, connectionAddress } = useSelector(state => state.auth);

  useEffect(() => {
    if (isConnected && authToken) {
      if (userInfoData && userInfoData?.wallet && userInfoData?.wallet?.address !== address) {
        toast.error(t('PleaseConnectWithYourRegisteredWallet'));
        disconnect();
      }
    }
  }, [isConnected]);

  return (
    <Layout>
      <Switch>
        {routes.map(route =>
          route.component ? (
            <Route key={route.name} path={route.path} exact={route.exact} name={route.name}>
              <route.component />
            </Route>
          ) : (
            route.redirectRoute && <Redirect key={route.name} to={route.path} />
          ),
        )}
      </Switch>
    </Layout>
  );
}

export default MainContainer;
