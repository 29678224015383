import * as actionLabels from '../../actionLabels';

export const getAffiliateList = payload => ({
  type: actionLabels.GET_AFFILIATE_LIST,
  payload,
});

export const getAffiliateListFail = payload => ({
  type: actionLabels.GET_AFFILIATE_LIST_FAIL,
  payload,
});

export const getAffiliateListSuccess = payload => ({
  type: actionLabels.GET_AFFILIATE_LIST_SUCCESS,
  payload,
});

export const getAffiliateInfo = payload => ({
  type: actionLabels.GET_AFFILIATE_INFO,
  payload,
});

export const getAffiliateInfoFail = payload => ({
  type: actionLabels.GET_AFFILIATE_INFO_FAIL,
  payload,
});

export const getAffiliateInfoSuccess = payload => ({
  type: actionLabels.GET_AFFILIATE_INFO_SUCCESS,
  payload,
});

export const getAffiliateDetails = payload => ({
  type: actionLabels.GET_AFFILIATE_DETAILS,
  payload,
});

export const getAffiliateDetailsFail = payload => ({
  type: actionLabels.GET_AFFILIATE_DETAILS_FAIL,
  payload,
});

export const getAffiliateDetailsSuccess = payload => ({
  type: actionLabels.GET_AFFILIATE_DETAILS_SUCCESS,
  payload,
});

export const getAffiliateInvestment = payload => ({
  type: actionLabels.GET_AFFILIATE_INVESTMENT,
  payload,
});

export const getAffiliateInvestmentFail = payload => ({
  type: actionLabels.GET_AFFILIATE_INVESTMENT_FAIL,
  payload,
});

export const getAffiliateInvestmentSuccess = payload => ({
  type: actionLabels.GET_AFFILIATE_INVESTMENT_SUCCESS,
  payload,
});

export const exportAffiliateInvestment = payload => ({
  type: actionLabels.EXPORT_AFFILIATE_INVESTMENT,
  payload,
});

export const exportAffiliateInvestmentFail = payload => ({
  type: actionLabels.EXPORT_AFFILIATE_INVESTMENT_FAIL,
  payload,
});

export const exportAffiliateInvestmentSuccess = payload => ({
  type: actionLabels.EXPORT_AFFILIATE_INVESTMENT_SUCCESS,
  payload,
});
