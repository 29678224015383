import * as actionLabels from '../../actionLabels';

export const projectOverviewSaga = payload => ({
    type: actionLabels.PROJECT_OVERVIEW,
    payload,
  });
  
  export const projectOverviewSagaFail = payload => ({
    type: actionLabels.PROJECT_OVERVIEW_FAIL,
    payload,
  });
  
  export const projectOverviewSagaSuccess = payload => ({
    type: actionLabels.PROJECT_OVERVIEW_SUCCESS,
    payload,
  });

  export const projectListSaga = payload => ({
    type: actionLabels.PROJECT_LIST,
    payload,
  });
  
  export const projectListSagaFail = payload => ({
    type: actionLabels.PROJECT_LIST_FAIL,
    payload,
  });
  
  export const projectListSagaSuccess = payload => ({
    type: actionLabels.PROJECT_LIST_SUCCESS,
    payload,
  });

  export const projectApprovalSaga = payload => ({
    type: actionLabels.PROJECT_APPROVAL,
    payload,
  });
  
  export const projectApprovalSagaFail = payload => ({
    type: actionLabels.PROJECT_APPROVAL_FAIL,
    payload,
  });
  
  export const projectApprovalSagaSuccess = payload => ({
    type: actionLabels.PROJECT_APPROVAL_SUCCESS,
    payload,
  });

  export const projectRejectSaga = payload => ({
    type: actionLabels.PROJECT_REJECT,
    payload,
  });
  
  export const projectRejectSagaFail = payload => ({
    type: actionLabels.PROJECT_REJECT_FAIL,
    payload,
  });
  
  export const projectRejectSagaSuccess = payload => ({
    type: actionLabels.PROJECT_REJECT_SUCCESS,
    payload,
  });

  export const getProjectDetailsSaga = payload => ({
    type: actionLabels.GET_PROJECT_DETAILS,
    payload,
  });
  
  export const getProjectDetailsSagaFail = payload => ({
    type: actionLabels.GET_PROJECT_DETAILS_FAIL,
    payload,
  });
  
  export const getProjectDetailsSagaSuccess = payload => ({
    type: actionLabels.GET_PROJECT_DETAILS_SUCCESS,
    payload,
  });

  export const uploadImageSaga = payload => ({
    type: actionLabels.UPLOAD_IMAGE,
    payload,
  });
  export const uploadImageStart = payload => ({
    type: actionLabels.UPLOAD_IMAGE_START,
    payload,
  });
  
  export const uploadImageSagaFail = payload => ({
    type: actionLabels.UPLOAD_IMAGE_FAIL,
    payload,
  });
  
  export const uploadImageSagaSuccess = payload => ({
    type: actionLabels.UPLOAD_IMAGE_SUCCESS,
    payload,
  });

  export const updateProjectSaga = payload => ({
    type: actionLabels.UPDATE_PROJECT,
    payload,
  });
  
  export const updateProjectSagaFail = payload => ({
    type: actionLabels.UPDATE_PROJECT_FAIL,
    payload,
  });
  
  export const updateProjectSagaSuccess = payload => ({
    type: actionLabels.UPDATE_PROJECT_SUCCESS,
    payload,
  });

  export const deleteProjectListSaga = payload => ({
    type: actionLabels.DELETE_PROJECT_LIST,
    payload,
  });
  
  export const deleteProjectListSagaFail = payload => ({
    type: actionLabels.DELETE_PROJECT_LIST_FAIL,
    payload,
  });
  
  export const deleteProjectListSagaSuccess = payload => ({
    type: actionLabels.DELETE_PROJECT_LIST_SUCCESS,
    payload,
  });

  export const deleteProjectSaga = payload => ({
    type: actionLabels.DELETE_PROJECT,
    payload,
  });
  
  export const deleteProjectSagaFail = payload => ({
    type: actionLabels.DELETE_PROJECT_FAIL,
    payload,
  });
  
  export const deleteProjectSagaSuccess = payload => ({
    type: actionLabels.DELETE_PROJECT_SUCCESS,
    payload,
  });

  export const clearUpdateProject = () => ({
    type: actionLabels.CLEAR_UPDATE_PROJECT,
  });

  export const clearApproveProject = () => ({
    type: actionLabels.CLEAR_APPROVE_PROJECT,
  });
