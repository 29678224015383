import * as Yup from 'yup';
import { CONTACT_SALES_REQUIRED } from 'constants/validationMessages';

export const rangeChipValidation = () => Yup.object().shape({
  chipType: Yup.string()
    .max(30, 'Tag type must be at most 30 characters')
    .required('Tag type is required'),
  minimumPurchaseQuantity: Yup.number()
    .min(1, 'Minimum purchase quantity must be greater than or equal to 1')
    .integer('Minimum purchase quantity must be an integer')
    .required('Minimum purchase quantity is required'),
  maximumPurchaseQuantity: Yup.number()
    .moreThan(
      Yup.ref('minimumPurchaseQuantity'),
      'Maximum purchase quantity must be greater than minimum purchase quantity',
    )
    .integer('Maximum purchase quantity must be an integer')
    .max(10000000, 'Maximum purchase quantity must be less than or equal to 10000000')
    .required('Maximum purchase quantity is required'),
  customlogo: Yup.string().required('Custom logo is required'),
  chipCustomImage: Yup.string().required('Custom colors is required'),
  noLogo: Yup.mixed().required('No logo is required'),
  defaultLogo: Yup.mixed().required('Default logo is required'),
  thumbnail: Yup.mixed().required('Thumbnail is required'),
});

export const staticChipValidation = () => Yup.object().shape({
  chipType: Yup.string()
    .max(30, 'Tag type must be at most 30 characters')
    .required('Tag type is required'),
  pricePerChip: Yup.string()
    .matches(
      /^[0-9]*(\.[0-9]{0,2})?$/,
      'Price per tag must be a number with up to two decimal places',
    )
    .required('Price per tag is required'),
  minimumPurchaseQuantity: Yup.number()
    .min(1, 'Minimum purchase quantity must be greater than or equal to 1')
    .integer('Minimum purchase quantity must be an integer')
    .required('Minimum purchase quantity is required'),
  maximumPurchaseQuantity: Yup.number()
    .moreThan(
      Yup.ref('minimumPurchaseQuantity'),
      'Maximum purchase quantity must be greater than minimum purchase quantity',
    )
    .integer('Maximum purchase quantity must be an integer')
    .max(10000000, 'Maximum purchase quantity must be less than or equal to 10000000')
    .required('Maximum purchase quantity is required'),
  customlogo: Yup.string().required('Custom logo is required'),
  chipCustomImage: Yup.string().required('Custom colors is required'),
  noLogo: Yup.mixed().required('No logo is required'),
  defaultLogo: Yup.mixed().required('Default logo is required'),
  thumbnail: Yup.mixed().required('Thumbnail is required'),
});

export const CreatePackageValidation = () => Yup.object().shape({
  chipPackageName: Yup.string()
    .max(30, 'Package Name must be at most 30 characters')
    .required('Package Name is required'),
});

export const createtemplateValidation = Yup.object().shape({
  type: Yup.string().required('Template type is required!'),
  thumbnail: Yup.string().required('LHS ui is required'),
  templateName: Yup.string()
    .max(30, 'Template Name must be at most 30 characters')
    .required('Template Name is required'),
});

export const createSubscriptionPlanValidation = Yup.object().shape({
  subscriptionName: Yup.string().required('Subscription name is required'),
  subscriptionBenefitId: Yup.string().required('Subscription Benefit is required'),
  amountPerMonth: Yup.number()
    .positive('Value must be greater than 0')
    .required('Monthly Cost is required'),
  amountPerYear: Yup.number()
    .positive('Value must be greater than 0')
    .required('Annual Cost is required'),
  isHighlight: Yup.string().required('Highlight Plan is required'),
  description: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Description is required'),
    }),
  ),
});

export const createBenefitValidation = Yup.object().shape({
  publishAccess: Yup.string().required('Publish Access is required'),
  emailNotification: Yup.string().required('Email Notification is required'),
  analyticsAccess: Yup.string().required('Analytic Access is required'),
  smsNotification: Yup.string().required('SMS Notification is required'),
  versionControl: Yup.string().required('Verison Control is required'),
  isDraftLimited: Yup.string().required('Draft Limited is required'),
  widgetAccess: Yup.array().required('Widget Access is required'),
  draftLimit: Yup.string().when('isDraftLimited', {
    is: (isDraftLimited) => isDraftLimited === 'yes',
    then: () => Yup.number().required('Draft Limit is Required'),
    otherwise: () => Yup.number(),
  }),
});

// export const youtubeLinkSchema = Yup.string().matches(
//   /^https?:\/\/(www\.)?youtube\.com\/(@[a-zA-Z0-9_]+|[a-zA-Z0-9_-]+(\/(user|channel)\/[a-zA-Z0-9_-]+)?)$/,
export const youtubeLinkSchema = Yup.string().matches(
  /^(https?:\/\/)?(www\.)?youtube\.com(\/.*)?$/,
  {
    message: 'Invalid YouTube link',
  },
);

// export const twitterLinkSchema = Yup.string().matches(
//   /^https?:\/\/(www\.)?twitter\.com\/([a-zA-Z0-9_]+\/?)$/,
//   {
export const twitterLinkSchema = Yup.string().matches(
  /^(https?:\/\/)?(www\.)?twitter\.com(\/.*)?$/,
  {
    message: 'Invalid Twitter link',
  },
);

// export const instagramLinkSchema = Yup.string().matches(
//   /^https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9_]+\/?)$/,
//   {
export const instagramLinkSchema = Yup.string().matches(
  /^(https?:\/\/)?(www\.)?instagram\.com(\/.*)?$/,
  {
    message: 'Invalid Instagram link',
  },
);

export const GoogleFormLinkValidator = Yup.string()
  .url('Enter valid contact sales url!')
  .required(CONTACT_SALES_REQUIRED);
