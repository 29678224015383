/* eslint-disable */
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { BACK_ICON_SVG, BACK_ICON_LIGHT } from 'assets/images';
import { useTranslation } from 'react-i18next';

function TitleCard({ title = '', showBackButton = false }) {
  const history = useHistory();
  const { Theme } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  return (
    <Row className="m-0">
      <Col className="col-12 p-0">
        <div className="page-title-box d-flex align-items-center ps-0">
          {showBackButton && (
          <img
            src={Theme === 'light' ? BACK_ICON_SVG : BACK_ICON_LIGHT}
            alt="back"
            className="h16px me-2 d-flex align-items-center cursor-pointer"
            onClick={() => history.goBack()}
          />
          )}
          <h3 className="mb-0 text_primary">
            {t(title)}
            {' '}
          </h3>
        </div>
      </Col>
    </Row>
  );
}

export default TitleCard;
