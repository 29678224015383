import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  platformHistorySagaSuccess,
  platformHistorySagaFail,
  getPlatformVariableSagaSuccess,
  getPlatformVariableSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import { PLATFORM_VARIABLE_OVERVIEW_URL, PLATFORM_HISTORY_URL } from 'apis';

export function* platformHistorySaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${PLATFORM_HISTORY_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(platformHistorySagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(platformHistorySagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getPlatformVariableSaga({ payload }) {
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${PLATFORM_VARIABLE_OVERVIEW_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getPlatformVariableSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getPlatformVariableSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
