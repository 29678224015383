import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

function BarChart() {
  const { graphData } = useSelector((state) => state.dashboard);
  const { t } = useTranslation();
  const data = {
    labels: graphData?.data?.map(item => `${item.projectName}, ${item.creator}`),
    datasets: [
      {
        label: t('Total Amount to be raised'),
        data: graphData?.data?.map(item => item.amountToBeRaised),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: t('Fund raised'),
        data: graphData?.data?.map(item => item.amountRaised),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
      label: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
    },
  };

  return <Bar data={data} options={options} className="bar-chart" />;
}

export default BarChart;
