import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'react-bootstrap';

import './modal.css';
import { useTranslation } from 'react-i18next';

function DeleteModal({
  show, close, title, confirm,
}) {
  const { Theme } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  return (
    <Modal
      id="view_modal"
      className="text-center delete_modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      data-theme={Theme}
    >
      <ModalBody
        // className="pt-4 pb-4"
        className="modal-body-css"
      >
        <h4 className="text_primary">{title}</h4>
        <div className="d-flex justify-content-evenly mt-4">
          <Button className="w150 cursor-pointer secondary-button-color" onClick={() => close()}>
            {t('No')}
          </Button>
          <Button className="w150 cursor-pointer primary-button-color" onClick={() => confirm()}>
            {t('Yes')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DeleteModal;
