import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  projectOverviewSagaSuccess,
  projectOverviewSagaFail,
  projectListSagaSuccess,
  projectListSagaFail,
  projectApprovalSagaSuccess,
  projectApprovalSagaFail,
  projectRejectSagaSuccess,
  projectRejectSagaFail,
  getProjectDetailsSagaSuccess,
  getProjectDetailsSagaFail,
  uploadImageSagaSuccess,
  uploadImageSagaFail,
  updateProjectSagaSuccess,
  updateProjectSagaFail,
  uploadImageStart,
  deleteProjectListSagaSuccess,
  deleteProjectListSagaFail,
  deleteProjectSagaSuccess,
  deleteProjectSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import {
  PROJECT_OVERVIEW_URL,
  GET_PROJECT_LIST_URL,
  DELETE_PROJECT_LIST_URL,
  PROJECT_APPROVE_URL,
  PROJECT_DETAILS_URL,
  UPLOAD_IMAGE_URL,
  UPDATE_PROJECT_URL,
  DELETE_PROJECT_URL,
  PROJECT_REJECT_URL,
} from 'apis';

export function* projectOverviewSaga({ payload }) {
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${PROJECT_OVERVIEW_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(projectOverviewSagaSuccess({ data }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(projectOverviewSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* projectListSaga({ payload }) {
  const { itemsPerPage, startIndex, search, status, startDate, endDate, sortBy, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (status) {
    params.status = status;
  }
  if (startDate || endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortBy) {
    params.sortBy = sortBy;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_PROJECT_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(projectListSagaSuccess({ response }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(projectListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* deleteProjectListSaga({ payload }) {
  const { itemsPerPage, startIndex, search, status, startDate, endDate, sortBy, sortValue } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (status) {
    params.status = status;
  }
  if (startDate || endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortBy) {
    params.sortBy = sortBy;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${DELETE_PROJECT_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(deleteProjectListSagaSuccess({ response }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(deleteProjectListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* projectApprovalSaga({ payload }) {
  const { approveProject, id } = payload;
  const params = {
    status: approveProject,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${PROJECT_APPROVE_URL}${id}`,
    payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(projectApprovalSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });

      yield put(projectApprovalSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}

export function* projectRejectionSaga({ payload }) {
  const { rejectProject, id, descp } = payload;
  const params = {
    status: rejectProject,
    feedback: descp,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${PROJECT_REJECT_URL}${id}`,
    payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(projectRejectSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(projectRejectSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}

export function* getProjectDetailsSaga({ payload }) {
  const { id } = payload;
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${PROJECT_DETAILS_URL}${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getProjectDetailsSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getProjectDetailsSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* uploadImageSaga({ payload }) {
  yield put(uploadImageStart());
  if (payload.loader) {
    payload.loader(payload.type, true);
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${UPLOAD_IMAGE_URL}`,
    payload: payload.formDatas,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(uploadImageSagaSuccess({ ...data[0], type: payload.type, index: payload.index }));
      if (payload.loader) {
        payload.loader(payload.type, false);
      }
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(uploadImageSagaFail(response));
      if (payload.loader) {
        payload.loader(payload.type, false);
      }
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
    token: true,
  });
}

export function* updateProjectSaga({ payload }) {
  const { id, param } = payload;
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${UPDATE_PROJECT_URL}${id}`,
    payload: param,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(updateProjectSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(updateProjectSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
    token: true,
  });
}

export function* deleteProjectSaga({ payload }) {
  const { id, isDelete, deletedReason } = payload;
  const param = {
    isDeleted: isDelete,
    deleteReason: deletedReason,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${DELETE_PROJECT_URL}${id}`,
    payload: param,
    successHandler: yield function* (response) {
      const { data } = response;
      yield handleToastfunction({ status: 'success', title: response?.message });
      yield put(deleteProjectSagaSuccess({ response }));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(deleteProjectSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}
