import * as actionLabels from '../../actionLabels';

export const getSwapList = payload => ({
  type: actionLabels.GET_SWAP_TRANSC_LIST,
  payload,
});

export const getSwapListFail = payload => ({
  type: actionLabels.GET_SWAP_TRANSC_LIST_FAIL,
  payload,
});

export const getSwapListSuccess = payload => ({
  type: actionLabels.GET_SWAP_TRANSC_LIST_SUCCESS,
  payload,
});
