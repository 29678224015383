import {
  PARTNER_STATS,
  PARTNER_STATS_FAIL,
  PARTNER_STATS_SUCCESS,
  WEBSITE_STATS,
  WEBSITE_STATS_FAIL,
  WEBSITE_STATS_SUCCESS,
  USER_SIGNUP_STATS,
  USER_SIGNUP_STATS_FAIL,
  USER_SIGNUP_STATS_SUCCESS,
  DASHBOARD_OVERVIEW_FAIL,
  DASHBOARD_OVERVIEW_SUCCESS,
  DASHBOARD_OVERVIEW,
  DASHBOARD_STATISTICS,
  DASHBOARD_STATISTICS_FAIL,
  DASHBOARD_STATISTICS_SUCCESS,
  DASHBOARD_GRAPH_SUCCESS,
  DASHBOARD_GRAPH,
  DASHBOARD_GRAPH_FAIL,
} from 'store/actionLabels';

export const partnerStatsSaga = payload => ({
  type: PARTNER_STATS,
  payload,
});

export const partnerStatsFail = payload => ({
  type: PARTNER_STATS_FAIL,
  payload,
});

export const partnerStatsSuccess = payload => ({
  type: PARTNER_STATS_SUCCESS,
  payload,
});

export const websiteStatsSaga = payload => ({
  type: WEBSITE_STATS,
  payload,
});

export const websiteStatsFail = payload => ({
  type: WEBSITE_STATS_FAIL,
  payload,
});

export const websiteStatsSuccess = payload => ({
  type: WEBSITE_STATS_SUCCESS,
  payload,
});

export const userSignupStatsSaga = payload => ({
  type: USER_SIGNUP_STATS,
  payload,
});

export const userSignupStatsFail = payload => ({
  type: USER_SIGNUP_STATS_FAIL,
  payload,
});

export const userSignupStatsSuccess = payload => ({
  type: USER_SIGNUP_STATS_SUCCESS,
  payload,
});

// dashboard api
export const dashboardOverviewSaga = payload => ({
  type: DASHBOARD_OVERVIEW,
  payload,
});

export const dashboardOverviewSagaFail = payload => ({
  type: DASHBOARD_OVERVIEW_FAIL,
  payload,
});

export const dashboardOverviewSagaSuccess = payload => ({
  type: DASHBOARD_OVERVIEW_SUCCESS,
  payload,
});

export const dashboardStatisticsSaga = payload => ({
  type: DASHBOARD_STATISTICS,
  payload,
});

export const dashboardStatisticsSagaFail = payload => ({
  type: DASHBOARD_STATISTICS_FAIL,
  payload,
});

export const dashboardStatisticsSagaSuccess = payload => ({
  type: DASHBOARD_STATISTICS_SUCCESS,
  payload,
});

export const dashboardGraphSaga = payload => ({
  type: DASHBOARD_GRAPH,
  payload,
});

export const dashboardGraphSagaFail = payload => ({
  type: DASHBOARD_GRAPH_FAIL,
  payload,
});

export const dashboardGraphSagaSuccess = payload => ({
  type: DASHBOARD_GRAPH_SUCCESS,
  payload,
});
