/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLogin: false,
  isLoading: false,
  userData: null,
  authToken: localStorage.getItem('authToken') || '',
  awsCognitoObj: null,
  errorMsg: '',
  isResetPassword: false,
  otpVerified: false,
  tempUserforotpverification: {},
  awsSession: null,
  // forgot auth
  forgotAuthenticationLoading: false,
  forgotAuthenticationErrorMSg: '',
  forgotEmail: '',
  // reset auth
  resetAuthenticationLoading: false,
  resetAuthenticationErrorMSg: '',
  // theme
  Theme: sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : 'light',
  connectionAddress: {},
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.LOGIN_START:
      return {
        ...state,
        isLoading: true,
        isLogin: false,
        errorMsg: '',
      };
    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        errorMsg: '',
        authToken: payload?.authToken,
        userData: payload?.userData,
      };
    }

    case actionLabels.LOGIN_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload || '',
        authToken: null,
        userData: null,
        awsCognitoObj: null,
      };
    }

    // forgot auth
    case actionLabels.FORGOT_AUTHENTICATOR_START: {
      return {
        ...state,
        forgotAuthenticationLoading: true,
        forgotAuthenticationErrorMSg: '',
      };
    }
    case actionLabels.FORGOT_AUTHENTICATOR_SUCCESS: {
      return {
        ...state,
        forgotAuthenticationLoading: false,
        forgotAuthenticationErrorMSg: '',
        forgotEmail: payload?.forgotResponse?.email,
      };
    }
    case actionLabels.FORGOT_AUTHENTICATOR_FAIL: {
      return {
        ...state,
        forgotAuthenticationLoading: false,
        forgotAuthenticationErrorMSg: payload,
      };
    }
    // language choose
    case actionLabels.LANGUAGE_CHOOSE_SAGA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionLabels.LANGUAGE_CHOOSE_SAGA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionLabels.LANGUAGE_CHOOSE_SAGA_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
