/*eslint-disable */
import React from "react";

import "./CryptoSpinner.css";

const CryptoSpinner = ({ className = "" }) => {
  return (
    <div className={`spinner_body ${className}`}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

  );
};

export default CryptoSpinner;
