/* eslint-disable */
import { connectorsForWallets, getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet
} from "@rainbow-me/rainbowkit/wallets";
import { createPublicClient, http } from 'viem';
import { polygonAmoy, sepolia } from 'wagmi/chains';

const projectId = '8c963e6e044549c04fdc38419fd26a01';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet],
    },
  ],
  {
    appName: 'My RainbowKit App',
    projectId: 'YOUR_PROJECT_ID',
  }
);

const config = getDefaultConfig({
  appName: 'Crypto Launchpad Admin',
  chains: [polygonAmoy],
  connectors,
  projectId
});

export const currentChain = polygonAmoy;

export const publicConfig = createPublicClient({
  chain: currentChain,
  transport: http(),
});

export default config;
