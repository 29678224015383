import { useSelector } from 'react-redux';
import { Navbar, NavbarBrand } from 'reactstrap';
import { CRYPTO_LOGO } from 'assets/images';

function GuestHeader({ children }) {
  const { Theme } = useSelector(state => state.auth);

  return (
    <div>
      <Navbar className="guest-header justify-content-between" expand="md" data-theme={Theme}>
        <NavbarBrand href="/">
          <div className="d-block auth-logo ps-3">
            <img src={CRYPTO_LOGO} alt="sllabslogo-sm" height="70" width="100" />
          </div>
        </NavbarBrand>
      </Navbar>
      {children}
    </div>
  );
}

export default GuestHeader;
