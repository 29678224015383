import '../index.css';

function SearchInput({
  onChange,
  placeholder,
  width = '100%',
  waitTime,
  minSearchChar,
  className = '',
}) {
  let tempInterval = '';
  const _handleChange = (val) => {
    const searchedKeywrod = val.target.value.trim();
    clearTimeout(tempInterval);
    tempInterval = setTimeout(() => {
      const qp = searchedKeywrod.length > (minSearchChar || 2) ? searchedKeywrod : '';
      onChange(qp);
    }, waitTime || 500);
  };

  return (
    <div className={`search-box ${className}`}>
      <div className="position-relative">
        <input
          className="form-control search_input w-100"
          type="search"
          style={{ width }}
          onChange={_handleChange}
          placeholder={placeholder || 'Search'}
        />
        <i className="mdi mdi-magnify search-icon" />
      </div>
    </div>
  );
}

export default SearchInput;
