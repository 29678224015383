import * as actionLabels from '../../actionLabels';

const initialState = {
  tokenGenerationData: {},
  platformFeeData: {},
  listingFeeData: {},
  userListData: {},
  exportListingFeeData: [],
  exportTokenGenerationFeeData: [],
  exportPlatformFeeData: [],
  isLoading: false,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_TOKEN_GENERATION_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.GET_TOKEN_GENERATION_FEE_SUCCESS:
      return {
        ...state,
        tokenGenerationData: payload,
        platformFeeData: {},
        listingFeeData: {},
        isLoading: false,
      };
    case actionLabels.GET_TOKEN_GENERATION_FEE_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.GET_PLATFORM_FEE_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.GET_PLATFORM_FEE_LIST_SUCCESS:
      return {
        ...state,
        tokenGenerationData: {},
        listingFeeData: {},
        platformFeeData: payload,
        isLoading: false,
      };
    case actionLabels.GET_PLATFORM_FEE_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.GET_LISTING_FEE_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.GET_LISTING_FEE_LIST_SUCCESS:
      return {
        ...state,
        tokenGenerationData: {},
        transactionExportList: {},
        platformFeeData: {},
        listingFeeData: payload,
        isLoading: false,
      };
    case actionLabels.GET_LISTING_FEE_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.GET_USER_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userListData: payload,
        isLoading: false,
      };
    case actionLabels.GET_USER_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.TRANSACTION_EXPORT_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.TRANSACTION_EXPORT_LIST_SUCCESS:
      return {
        ...state,
        transactionExportList: payload,
        isLoading: false,
      };
    case actionLabels.TRANSACTION_EXPORT_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.EXPORT_LISTING_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.EXPORT_LISTING_FEE_SUCCESS:
      return {
        ...state,
        exportTokenGenerationFeeData: [],
        exportPlatformFeeData: [],
        exportListingFeeData: payload,
        isLoading: false,
      };
    case actionLabels.EXPORT_LISTING_FEE_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.EXPORT_TOKENGENERATION_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.EXPORT_TOKENGENERATION_FEE_SUCCESS:
      return {
        ...state,
        exportTokenGenerationFeeData: payload,
        exportListingFeeData: [],
        exportPlatformFeeData: [],
        isLoading: false,
      };
    case actionLabels.EXPORT_TOKENGENERATION_FEE_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.EXPORT_PLATFORM_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.EXPORT_PLATFORM_FEE_SUCCESS:
      return {
        ...state,
        exportTokenGenerationFeeData: [],
        exportListingFeeData: [],
        exportPlatformFeeData: payload,
        isLoading: false,
      };
    case actionLabels.CLEAR_EXPORT_DATA:
      return {
        ...state,
        exportPlatformFeeData: [],
        exportTokenGenerationFeeData: [],
        exportListingFeeData: [],
      };

    case actionLabels.EXPORT_PLATFORM_FEE_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }

    default:
      return state;
  }
};
