import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import { handleToastfunction } from 'utils';
import {
  loginSuccess,
  loginFail,
  loginStart,
  forgotAuthenticatorStart,
  forgotAuthenticatorSuccess,
  forgotAuthenticatorFail,
  languageChooseSagaStart,
  languageChooseSagaSuccess,
  languageChooseSagaFail,
} from '../../actions';
import { LOGIN_URL, CHANGE_PASSWORD_URL, LANGUAGE_CHOOSE_URL } from 'apis';

export function* loginSaga({ payload }) {
  yield put(loginStart());

  if (payload) {
    yield errorHandler({
      endpoint: `${process.env.REACT_APP_END_POINT}${LOGIN_URL}`,
      successHandler: yield function* (response) {
        const { data } = response;
        yield put(loginSuccess({ userData: data, authToken: data.token }));

        localStorage.setItem('authToken', data.token);
        if (payload.success) {
          payload.success(response);
        }
      },
      failHandler: yield function* (response) {
        yield put(loginFail(response));
      },
      payload: {
        email: payload?.email,
        password: payload?.password,
      },
      failHandlerType: 'CUSTOM',
      baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN_AUTH,
      apiType: 'post',
    });
  } else {
    yield put(loginFail('Not Login'));
  }
}

const loadState = () => ({
  authToken: localStorage.getItem('authToken'),
  userData: JSON.parse(localStorage.getItem('userData')),
});
export function* authenticationValidatorSaga() {
  yield put(loginStart());
  const state = loadState();
  if (!state?.authToken) {
    yield put(loginFail(''));
  } else {
    yield put(loginSuccess(state));
  }
}

// forgot authenticator
export function* forgotAuthenticatorSaga({ payload }) {
  const { close } = payload;
  yield put(forgotAuthenticatorStart());
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${CHANGE_PASSWORD_URL}`,
    successHandler: yield function* (response) {
      yield put(forgotAuthenticatorSuccess(response));
      handleToastfunction({ status: 'success', title: response?.message });
      if (close) close();
    },
    failHandler: yield function* (response) {
      yield put(forgotAuthenticatorFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    payload: {
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
    },
    baseAxios: process.env.REACT_APP_END_POINT,
    failHandlerType: 'CUSTOM',
    apiType: 'patch',
  });
}

// language choose
export function* languageChooseSaga({ payload }) {
  const { lang } = payload;
  const param = { language: lang };
  yield put(languageChooseSagaStart());
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${LANGUAGE_CHOOSE_URL}`,
    payload: param,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(languageChooseSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(languageChooseSagaFail(response));
      handleToastfunction({ status: 'error', title: response });
    },
    baseAxios: process.env.REACT_APP_END_POINT,
    failHandlerType: 'CUSTOM',
    apiType: 'patch',
  });
}
