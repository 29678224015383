import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  getTokenGenerationFeeSagaSuccess,
  getTokenGenerationFeeSagaFail,
  getPlatformFeeSagaSuccess,
  getPlatformFeeSagaFail,
  getListingFeeSagaSuccess,
  getListingFeeSagaFail,
  transactionExportListSagaSuccess,
  transactionExportListSagaFail,
  getUserListSagaSuccess,
  getUserListSagaFail,
  exportListingFeeSagaSuccess,
  exportListingFeeSagaFail,
  exportTokenGenerationFeeSagaSuccess,
  exportTokenGenerationFeeSagaFail,
  exportPlatformFeeSagaSuccess,
  exportPlatformFeeSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import {
  GET_TOKEN_GEN_FEE_URL,
  GET_PLATFORM_FEE_URL,
  GET_LISTING_FEE_URL,
  GET_TRANSACTION_LIST_URL,
  TRANSACTION_EXPORT_LIST_URL,
  EXPORT_LISTING_FEE_URL,
  EXPORT_TOKEN_GEN_FEE_URL,
  EXPORT_PLATFORM_FEE_URL,
} from 'apis';

export function* getTokenGenerationFeeSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_TOKEN_GEN_FEE_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getTokenGenerationFeeSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getTokenGenerationFeeSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getPlatformFeeSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_PLATFORM_FEE_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getPlatformFeeSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getPlatformFeeSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getListingFeeSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_LISTING_FEE_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getListingFeeSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getListingFeeSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getUserListSaga({ payload }) {
  const { itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } = payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_TRANSACTION_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getUserListSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getUserListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* transactionExportListSaga({ payload }) {
  const { search, startDate, endDate, userType } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${TRANSACTION_EXPORT_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(transactionExportListSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(transactionExportListSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* exportListingFeeSaga({ payload }) {
  const { search, startDate, endDate, userType } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${EXPORT_LISTING_FEE_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(exportListingFeeSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(exportListingFeeSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* exportTokenGenerationFeeSaga({ payload }) {
  const { search, startDate, endDate, userType } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${EXPORT_TOKEN_GEN_FEE_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(exportTokenGenerationFeeSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(exportTokenGenerationFeeSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* exportPlatformFeeSaga({ payload }) {
  const { search, startDate, endDate, userType } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${EXPORT_PLATFORM_FEE_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(exportPlatformFeeSagaSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(exportPlatformFeeSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
