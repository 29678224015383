 // required field error
export const REQUIRED = 'This field is required.';
export const EMAIL_REQUIRED = 'Email is required';
export const PASSWORD_REQUIRED = 'Password is required';
export const OLD_PASSWORD_REQUIRED = 'Old Password is required';
export const NEW_PASSWORD_REQUIRED = 'New Password is required';
export const CONFIRM_PASSWORD_REQUIRED = 'Confirm Password is required';

// validation error
export const VALID_EMAIL = 'Enter a valid email address';
export const VALID_PASSWORD = 'It must have atleast 8 characters, 1 Uppercase, 1 Lowercase, 1 digit & 1 special character';
export const VALID_CONFIRM_PASSWORD = 'Passwords must match';
export const VALID_OTP_ERROR = 'Please enter valid OTP';

// toast messages
export const OTP_SENT_ON_EMAIL = 'OTP is sent, please check your email';
export const PASSWORD_SUCCESS_MESSAGE = 'Password changed successfully';
export const PRICE_PER_CHIP_REQUIRED = 'Price per chip is required';
export const PRICE_PER_CHIP_ERROR = 'Price per chip must be a positive number';
export const DECIMAL_ERROR = 'Price per chip cannot have more than 2 decimal places';
export const CONTACT_SALES_REQUIRED = 'Contact sales is required';
export const VALID_LINK_ERROR = '"Please enter valid link"';

// access code
export const VALID_ACCESS_CODE_ERROR = 'Access code accepts length of 1-50 having combination of Alphanumeric & special characters';
export const BLANKSPACE_ACCESS_CODE_ERROR = 'Access code does not accept blank spaces';
