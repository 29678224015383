/* eslint-disable import/no-anonymous-default-export */

import {
  PROJECT_OVERVIEW,
  PROJECT_OVERVIEW_SUCCESS,
  PROJECT_OVERVIEW_FAIL,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_LIST,
  PROJECT_APPROVAL,
  PROJECT_APPROVAL_FAIL,
  PROJECT_APPROVAL_SUCCESS,
  PROJECT_REJECT_SUCCESS,
  PROJECT_REJECT_FAIL,
  PROJECT_REJECT,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_FAIL,
  GET_PROJECT_DETAILS_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPLOAD_IMAGE_START,
  DELETE_PROJECT_LIST,
  DELETE_PROJECT_LIST_FAIL,
  DELETE_PROJECT_LIST_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  DELETE_PROJECT,
  CLEAR_UPDATE_PROJECT,
  CLEAR_APPROVE_PROJECT,
} from 'store/actionLabels';

const initialState = {
  isLoading: false,
  overviewData: {},
  listData: {},
  approveBtnData: {},
  projectDetailData: {},
  isApprovalComplete: false,
  isRejectionComplete: false,
  updateProjectComplete: {},
  imgData: {},
  deleteListData: {},
  uploadLoading: false,
  isDelete: false,
  projectOverviewLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PROJECT_OVERVIEW:
      return {
        ...state,
        projectOverviewLoading: true,
      };
    case PROJECT_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviewData: payload,
        projectOverviewLoading: false,
      };
    case PROJECT_OVERVIEW_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        projectOverviewLoading: false,
        content: {},
      };
    }
    case PROJECT_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case PROJECT_LIST_SUCCESS:
      return {
        ...state,
        listData: payload,
        isLoading: false,
      };
    case PROJECT_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case PROJECT_APPROVAL:
      return {
        ...state,
        isLoading: true,
        isApprovalComplete: false,
      };
    case PROJECT_APPROVAL_SUCCESS:
      return {
        ...state,
        approveBtnData: payload,
        isApprovalComplete: true,
        isLoading: false,
      };
    case PROJECT_APPROVAL_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        isApprovalComplete: false,
        content: {},
      };
    }
    case PROJECT_REJECT:
      return {
        ...state,
        isLoading: true,
        isRejectionComplete: false,
      };
    case PROJECT_REJECT_SUCCESS:
      return {
        ...state,
        approveBtnData: payload,
        isRejectionComplete: true,
        isLoading: false,
      };
    case PROJECT_REJECT_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        isRejectionComplete: false,
        content: {},
      };
    }
    case GET_PROJECT_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetailData: payload,
        isLoading: false,
      };
    case GET_PROJECT_DETAILS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case UPLOAD_IMAGE:
      return {
        ...state,
        uploadLoading: true,
      };
    case UPLOAD_IMAGE_START:
      return {
        ...state,
        uploadLoading: true,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imgData: payload,
        uploadLoading: false,
      };
    case UPLOAD_IMAGE_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        uploadLoading: false,
        content: {},
      };
    }
    case UPDATE_PROJECT:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateProjectComplete: payload,
      };
    case UPDATE_PROJECT_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }

    case DELETE_PROJECT_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteListData: payload,
        listData: {},
      };
    case DELETE_PROJECT_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case DELETE_PROJECT:
      return {
        ...state,
        isLoading: true,
        isDelete: false,
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDelete: true,
      };
    case DELETE_PROJECT_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        isDelete: false,
        content: {},
      };
    }
    case CLEAR_UPDATE_PROJECT: {
      return {
        ...state,
        updateProjectComplete: {}, 
      };
    }
    case CLEAR_APPROVE_PROJECT: {
      return {
        ...state,
        isApprovalComplete: false, 
      };
    }
    default:
      return state;
  }
};
