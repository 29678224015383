export const PARTNER_STATS = 'PARTNER_STATS';
export const PARTNER_STATS_FAIL = 'PARTNER_STATS_FAIL';
export const PARTNER_STATS_SUCCESS = 'PARTNER_STATS_SUCCESS';

export const WEBSITE_STATS = 'WEBSITE_STATS';
export const WEBSITE_STATS_FAIL = 'WEBSITE_STATS_FAIL';
export const WEBSITE_STATS_SUCCESS = 'WEBSITE_STATS_SUCCESS';

export const USER_SIGNUP_STATS = 'USER_SIGNUP_STATS';
export const USER_SIGNUP_STATS_FAIL = 'USER_SIGNUP_STATS_FAIL';
export const USER_SIGNUP_STATS_SUCCESS = 'USER_SIGNUP_STATS_SUCCESS';
// launchpad api
export const DASHBOARD_OVERVIEW = 'DASHBOARD_OVERVIEW';
export const DASHBOARD_OVERVIEW_FAIL = 'DASHBOARD_OVERVIEW_FAIL';
export const DASHBOARD_OVERVIEW_SUCCESS = 'DASHBOARD_OVERVIEW_SUCCESS';
export const DASHBOARD_STATISTICS = 'DASHBOARD_STATISTICS';
export const DASHBOARD_STATISTICS_FAIL = 'DASHBOARD_STATISTICS_FAIL';
export const DASHBOARD_STATISTICS_SUCCESS = 'DASHBOARD_STATISTICS_SUCCESS';
export const DASHBOARD_GRAPH = 'DASHBOARD_GRAPH';
export const DASHBOARD_GRAPH_FAIL = 'DASHBOARD_GRAPH_FAIL';
export const DASHBOARD_GRAPH_SUCCESS = 'DASHBOARD_GRAPH_SUCCESS';
