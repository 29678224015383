import * as actionLabels from '../../actionLabels';

export const loginStart = () => ({
  type: actionLabels.LOGIN_START,
});

export const login = (payload) => ({
  type: actionLabels.LOGIN_SAGA,
  payload,
});

export const loginSuccess = (payload) => ({
  type: actionLabels.LOGIN_SUCCESS,
  payload,
});

export const loginFail = (payload) => ({
  type: actionLabels.LOGIN_FAIL,
  payload,
});

// forgot Authenticator
export const forgotAuthenticatorAction = (payload) => ({
  type: actionLabels.FORGOT_AUTHENTICATOR_SAGA,
  payload,
});

export const forgotAuthenticatorStart = (payload) => ({
  type: actionLabels.FORGOT_AUTHENTICATOR_START,
  payload,
});

export const forgotAuthenticatorSuccess = (payload) => ({
  type: actionLabels.FORGOT_AUTHENTICATOR_SUCCESS,
  payload,
});

export const forgotAuthenticatorFail = (payload) => ({
  type: actionLabels.FORGOT_AUTHENTICATOR_FAIL,
  payload,
});
export const resetForgotAuthenticator = (payload) => ({
  type: actionLabels.RESET_FORGOT_AUTHENTICATOR,
  payload,
});

// LANGUAGE 
export const languageChooseSaga = (payload) => ({
  type: actionLabels.LANGUAGE_CHOOSE_SAGA,
  payload,
});

export const languageChooseSagaStart = (payload) => ({
  type: actionLabels.LANGUAGE_CHOOSE_SAGA_START,
  payload,
});

export const languageChooseSagaSuccess = (payload) => ({
  type: actionLabels.LANGUAGE_CHOOSE_SAGA_SUCCESS,
  payload,
});

export const languageChooseSagaFail = (payload) => ({
  type: actionLabels.LANGUAGE_CHOOSE_SAGA_FAIL,
  payload,
});
