import * as Yup from 'yup';

import { PASSWORD_REGEX } from 'constants/regexConstants';
import {
  EMAIL_REQUIRED,
  VALID_EMAIL,
  VALID_CONFIRM_PASSWORD,
  NEW_PASSWORD_REQUIRED,
  VALID_PASSWORD,
  CONFIRM_PASSWORD_REQUIRED,
  OLD_PASSWORD_REQUIRED,
} from 'constants/validationMessages';

export const forgotPasswordValidator = Yup.object().shape({
  email: Yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED),
});

export const resetPasswordValidator = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .matches(PASSWORD_REGEX, VALID_PASSWORD)
    .required(NEW_PASSWORD_REQUIRED),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], VALID_CONFIRM_PASSWORD)
    .required(CONFIRM_PASSWORD_REQUIRED),
});

export const changePasswordValidator = (t) => Yup.object().shape({
  oldPassword: Yup.string().required(t(OLD_PASSWORD_REQUIRED)),
  newPassword: Yup.string()
    .trim()
    .matches(PASSWORD_REGEX, t(VALID_PASSWORD))
    .required(t(NEW_PASSWORD_REQUIRED)),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], t(VALID_CONFIRM_PASSWORD))
    .required(t(CONFIRM_PASSWORD_REQUIRED)),
});
