export const PROJECT_OVERVIEW = 'PROJECT_OVERVIEW';
export const PROJECT_OVERVIEW_FAIL = 'PROJECT_OVERVIEW_FAIL';
export const PROJECT_OVERVIEW_SUCCESS = 'PROJECT_OVERVIEW_SUCCESS';

export const PROJECT_LIST = 'PROJECT_LIST';
export const PROJECT_LIST_FAIL = 'PROJECT_LIST_FAIL';
export const PROJECT_LIST_SUCCESS = 'PROJECT_LIST_SUCCESS';

export const PROJECT_APPROVAL = 'PROJECT_APPROVAL';
export const PROJECT_APPROVAL_FAIL = 'PROJECT_APPROVAL_FAIL';
export const PROJECT_APPROVAL_SUCCESS = 'PROJECT_APPROVAL_SUCCESS';

export const PROJECT_REJECT = 'PROJECT_REJECT';
export const PROJECT_REJECT_FAIL = 'PROJECT_REJECT_FAIL';
export const PROJECT_REJECT_SUCCESS = 'PROJECT_REJECT_SUCCESS';

export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
export const GET_PROJECT_DETAILS_FAIL = 'GET_PROJECT_DETAILS_FAIL';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';

export const DELETE_PROJECT_LIST = 'DELETE_PROJECT_LIST';
export const DELETE_PROJECT_LIST_FAIL = 'DELETE_PROJECT_LIST_FAIL';
export const DELETE_PROJECT_LIST_SUCCESS = 'DELETE_PROJECT_LIST_SUCCESS';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';

export const CLEAR_UPDATE_PROJECT = 'CLEAR_UPDATE_PROJECT';
export const CLEAR_APPROVE_PROJECT = 'CLEAR_APPROVE_PROJECT';
