/* eslint-disable */
import React from 'react';

export const guestRoutes = [
  {
    path: '/signin',
    name: 'Signin',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Login')),
  },
];

export const userRoutes = [
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: React.lazy(() => import('../../views/user/profle/profile')),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    component: React.lazy(() => import('../../views/user/Dashboard/Dashboard')),
  },
  {
    path: '/project',
    name: 'Project',
    exact: true,  
    component: React.lazy(() => import('../../views/user/project/Project')),
  },
  {
    path: '/users',
    name: 'User',
    exact: true,  
    component: React.lazy(() => import('../../views/user/users/User')),
  },
  {
    path: '/transactions',
    name: 'Transaction',
    exact: true,  
    component: React.lazy(() => import('../../views/user/transaction/Transaction')),
  },
  {
    path: '/platform-variable',
    name: 'Platform',
    exact: true,  
    component: React.lazy(() => import('../../views/user/platform/PlatformVariable')),
  },
  {
    path: '/project-detail/:id',
    name: 'ProjectDetail',
    exact: true,
    component: React.lazy(() => import('../../views/user/project/ProjectDetail')),
  },
  {
    path: '/user-detail/:id',
    name: 'UserDetail',
    exact: true,
    component: React.lazy(() => import('../../views/user/users/ViewUserDetail')),
  },
  {
    path: '/project-approval/:id',
    name: 'ProjectDetailApproval',
    exact: true,
    component: React.lazy(() => import('../../views/user/project/ProjectApproveDetail')),
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    exact: true,
    component: React.lazy(() => import('../../views/user/cms/TermsOfUse')),
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    exact: true,
    component: React.lazy(() => import('../../views/user/cms/PrivacyPolicy')),
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    exact: true,
    component: React.lazy(() => import('../../views/user/cms/AboutUs')),
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    exact: true,
    component: React.lazy(() => import('../../views/user/affiliate/Affiliate')),
  },
  {
    path: '/swap-transaction',
    name: 'swap',
    exact: true,
    component: React.lazy(() => import('../../views/user/swap/SwapTransaction')),
  },
  {
    path: '/affiliate-detail/:id',
    name: 'affiliateDetail',
    exact: true,
    component: React.lazy(() => import('../../views/user/affiliate/AffiliateDetails')),
  },
  {
    path: '/affiliate-investment/:id',
    name: 'affiliateInvestment',
    exact: true,
    component: React.lazy(() => import('../../views/user/affiliate/AffiliateInvestment')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/dashboard',
  },
];
