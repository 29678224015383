import { put } from 'redux-saga/effects';
import { axiosMain, setBaseUrl } from '../http/axios/axios_main';
import { useDisconnect } from 'wagmi';

export default function* errorHandler({
  endpoint,
  params = null,
  successHandler = () => null,
  failHandler = () => null,
  failHandlerType = '',
  payload = {},
  apiType = '',
  token = false,
  accessToken = null,
  isLogoutCall = false,
  baseAxios = '',
}) {
  setBaseUrl(baseAxios);
  // const disconnect = useDisconnect();
  let queryString = '';
  if (params) {
    queryString = new URLSearchParams(JSON.parse(params)).toString();
  }
  if (apiType.trim() === '') {
    throw new Error('apiType is required');
  }

  try {
    let response;
    const config = localStorage.getItem('authToken')
      ? {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      : {};
    const fullEndpoint = queryString ? `${endpoint}?${queryString}` : endpoint;
    switch (apiType.toLowerCase()) {
      case 'get':
        response = yield axiosMain.get(fullEndpoint, config);
        break;
      case 'post':
        response = yield axiosMain.post(endpoint, payload, config);
        break;
      case 'put':
        response = yield axiosMain.put(endpoint, payload, config);
        break;
      case 'patch':
        response = yield axiosMain.patch(endpoint, payload, config);
        break;
      case 'delete':
        response = yield axiosMain.delete(endpoint, config);
        break;
      default:
        throw new Error(`Unsupported apiType: ${apiType}`);
    }

    if (response && (response.status === 200 || response.status === 201) && response.data) {
      yield successHandler(response.data);
    } else {
      yield handleFailure(response, failHandler, failHandlerType);
    }
  } catch (error) {
    if (error.response.status === 401) {
      // sessionStorage.clear();
      localStorage.clear();
      // disconnect();
      window.location.reload();
    } else {
      yield handleFailure(error.response, failHandler, failHandlerType);
    }
  }
}

function* handleFailure(response, failHandler, failHandlerType) {
  const errorMessage =
    response?.data?.message ||
    response?.data?.errorMessage ||
    'Server error! Please try again later.';
  if (failHandlerType === 'CUSTOM') {
    yield failHandler(errorMessage);
  } else {
    yield put(failHandler(errorMessage));
  }
}
