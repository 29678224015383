/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  isLoading: false,
  cmsDetails: {},
  updatedCms: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.UPDATE_CMS:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.UPDATE_CMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updatedCms: payload,
      };
    case actionLabels.UPDATE_CMS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.GET_CMS_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.GET_CMS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cmsDetails: payload,
      };
    case actionLabels.GET_CMS_DETAILS_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    default:
      return state;
  }
};
