/* eslint-disable */
import { useFormikContext, getIn } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import OtpInput from 'otp-input-react';
import { useDropzone } from 'react-dropzone';
import RenderIf from '../RenderIf';
import {
  CALENDAR_ICON,
  DANGER_ICON_SVG,
  EYE_SLASH_SVG,
  EYE_SVG,
  UPLOAD_PNG,
} from '../../assets/images';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

export function CustomInput({
  icon,
  field,
  form: { touched, errors },
  className = '',
  inputClassName = '',
  errorClassName = '',
  label = '',
  name = '',
  inputId = '',
  withOutLabel = false,
  requiredField = false,
  showError = true,
  showEyeIcon = false,
  showPassword = false,
  setShowPassword,
  showDangerText = false,
  ...props
}) {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  return (
    <>
      <div
        id={showError && error && touch ? inputId : ''}
        className={`${className} each_input w-100 `}
        //  ${showError && error && touch ? 'mb30' : ''}
        style={props.style}
      >
        {withOutLabel && (
          <label className="custom-label fw-medium mb-2" htmlFor={name}>
            {label}
            {' '}
            {requiredField && <span className="mendatory_sign" />}
          </label>
        )}
        <div className="position-relative">
          <input
            className={
              'form-control'
              + ((showError || showDangerText) && error && touch ? ' is-invalid' : '')
              + ' '
              + inputClassName
            }
            {...field}
            {...props}
          />
          {showError && error && touch && (
            <div className={`invalid-feedback ${errorClassName}`}>{error}</div>
          )}
          <RenderIf isTrue={icon}>
            <img src={icon} className="input_icon" alt="icon" />
          </RenderIf>
          <RenderIf isTrue={showEyeIcon}>
            <img
              src={showPassword ? EYE_SVG : EYE_SLASH_SVG}
              className="pw_eye cursor-pointer"
              alt="password"
              onClick={() => setShowPassword(!showPassword)}
            />
          </RenderIf>
          <RenderIf isTrue={showDangerText}>
            <div className="d-flex pt-2">
              <img src={DANGER_ICON_SVG} className="pr-2" alt="danger" />

              <p className={`caution_text ${error && touch && 'text-danger'}`}>
                password is not valid
              </p>
            </div>
          </RenderIf>
        </div>
      </div>

      {/* <RenderIf isTrue={showEyeIcon}>
        <i
          className={`fa-solid ${
            showPassword ? "fa-eye" : "fa-eye-slash"
          } position-abs password-eye-icon`}
          onClick={() => setShowPassword(!showPassword)}
        />
      </RenderIf> */}
    </>
  );
}

export function CustomTextArea({
  field,
  form: { touched, errors },
  withOutLabel,
  className,
  textAreaClassName,
  textAreaId,
  requiredField,
  ...props
}) {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  return (
    <div className={className + ' each_input'} style={props.style}>
      {!withOutLabel && (
        <label className="custom-label" htmlFor={props.name}>
          {props.label}
          {' '}
          {requiredField && <span className="mendatory_sign" />}
        </label>
      )}
      <textarea
        id={textAreaId}
        className={'form-control ' + textAreaClassName + ' ' + (error && touch && 'is-invalid')}
        {...field}
        {...props}
      />
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomDropdown({
  field,
  form: { touched, errors },
  withOutLabel,
  className,
  selectClassName,
  requiredField,
  placeholder,
  proprty = 'value',
  access = 'label',
  ...props
}) {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  return (
    <div className={className + ' w-100'} style={props.style}>
      {!withOutLabel && (
      <span className="custom-label fw-medium">
        {props.label}
        {' '}
        {requiredField && <span className="mendatory_sign">*</span>}
      </span>
      )}

      <select
        className={
            'form-select form-control custom-select '
            + selectClassName
            + (error && touch && ' is-invalid')
          }
        {...field}
        {...props}
        onChange={(e) => {
          field.onChange(e);
          if (props.handleChange) {
            props.handleChange(e);
          }
        }}
      >
        {placeholder && (
        <option value="" disabled selected>
          {placeholder}
        </option>
        )}
        {props.data
            && props.data.length
            && props.data.map((i, idx) => (
              <option key={idx} value={i[proprty]}>
                {i[access]}
              </option>
            ))}
      </select>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomReactSelect({
  field,
  options = [],
  isMulti = false,
  value = '',
  form: { touched, errors },
  isSearchable = true,
  isLoading = false,
  handleChange = () => null,
  isRequired = true,
  ...props
}) {
  const touch = getIn(touched, field?.name);
  const error = getIn(errors, field?.name);
  const { setFieldValue } = useFormikContext();
  const onChange = (option) => {
    setFieldValue(field?.name, option?.length === 0 ? '' : option);
    handleChange();
  };

  return (
    <div className={props.styleData + ' '} style={props.style}>
      {!props.withOutLabel && (
      <span className="custom-label fw-medium">
        {props.label}
        {' '}
        {props.requiredField && <span className="mendatory_sign">*</span>}
      </span>
      )}
      <Select
        className={' ' + (error && touch && 'is-invalid')}
        classNamePrefix={props?.prefix || 'react-select-custom'}
        name={field?.name}
        onChange={onChange}
        placeholder={props?.placeholder}
        options={options}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isLoading={isLoading}
        value={value}
        classNames="form-select "
        required={isRequired}
      />
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomRadioButton({
  field,
  // form: { touched, errors },
  withOutLabel,
  className = '',
  inputClassName = '',
  requiredField,
  checkedVale,
  labelClassName = '',
  updatedName = '',
  onChange,
  ...props
}) {
  // const touch = getIn(touched, field.name);
  // const error = getIn(errors, field.name);
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <div className={className + ' form-check'}>
        <input
          type="radio"
          className={'form-check-input ' + inputClassName}
          id={props.id || props.label}
          name={updatedName || field.name}
          defaultChecked={props.defaultChecked}
          value={props.val}
          checked={checkedVale}
          onChange={() => {
            if (onChange) {
              onChange();
            } else {
              setFieldValue(field.name, props.val);
            }
          }}
        />
        &nbsp;
        {!withOutLabel && (
          <label
            className={`form-check-label general-p-text ${labelClassName}`}
            htmlFor={props.id || props.label}
          >
            {props.label}
            {' '}
            {requiredField && <span className="mendatory_sign" />}
          </label>
        )}
      </div>

      {/* {props.lastRadioButton && error && touch && <div className="invalid-feedback">{error}</div>} */}
    </>
  );
}

export function CustomCheckbox({
  field,
  form: { touched, errors },
  className = '',
  mainClassName = '',
  inputClassName = '',
  showError = true,
  handleChange,
  ...props
}) {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  const { setFieldValue } = useFormikContext();
  return (
    <div className={mainClassName}>
      <div className={className}>
        <input
          name={props.name}
          id={props.id}
          type="checkbox"
          className={`form-check-input me-2 ${inputClassName}`}
          checked={props?.val}
          onChange={() => {
            if (handleChange) {
              return handleChange(setFieldValue);
            }
            return setFieldValue(field.name, !props.val);
          }}
          disabled={props.disabled}
        />
        <label className="form-check-label general-p-text" htmlFor={props.id}>
          {props.label}
        </label>
      </div>
      {showError && error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomDateInputLog({
  field,
  form: { touched, errors },
  withOutLabel,
  className = '',
  dateFormat = 'dd/MM/yyyy',
  maxDate = new Date(),
  showCalenderIcon,
  calendarClassName = '',
  onchangeDateHandle,
  disabled,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  const { showMonth } = props;
  const { showYear } = props;
  return (
    <div className={`form-group ${className} w-100`}>
      {!withOutLabel && (
        <label htmlFor className="">
          {props.label}
        </label>
      )}
      <div className="input-group mb-0 position-relative" data-value="parent">
        <DatePicker
          data-value="child"
          dateFormat={dateFormat}
          autoComplete="off"
          // className={
          //   // "form-control shadow-none cursor-pointer " +
          //   error && touch && "is-invalid"
          // }
          className="w-100"
          {...field}
          {...props}
          value={
            field.value
              // ? props?.type === 'UPDATE_PROMO'
              //   ? formatUTC(field.value, true)
              //   : new Date(field.value)
              // : null
          }
          placeholderText={props.placeholder}
          selected={
            field.value
              // ? props?.type === 'UPDATE_PROMO'
              //   ? formatUTC(field.value, true)
              //   : new Date(field.value)
              // : null
          }
          onChange={(val) => {
            setFieldValue(field.name, val);
            if (onchangeDateHandle) onchangeDateHandle();
          }}
          showMonthDropdown={showMonth}
          showYearDropdown={showYear}
          dropdownMode="select"
          maxDate={maxDate}
          closeCalendar
          disabled={disabled}
        />
        <RenderIf isTrue={showCalenderIcon}>
          <img
            src={CALENDAR_ICON}
            className={`${calendarClassName} cursor-pointer`}
            alt="calendar"
          />
        </RenderIf>
      </div>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomTimeInputLog({
  field,
  form: { touched, errors },
  withOutLabel,
  className = '',
  dateFormat = 'h:mm aa',
  handleChange = false,
  timeIntervals = 15,
  value,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  const { showMonth } = props;
  const { showYear } = props;
  return (
    <div className={`form-group ${className}`}>
      {!withOutLabel && (
        <label htmlFor className="small text-secondary fw-600 pb-3">
          {props.label}
        </label>
      )}
      <div className="input-group mb-0">
        <DatePicker
          dateFormat={dateFormat}
          autoComplete="off"
          className={'form-control shadow-none  ' + (error && touch && 'is-invalid')}
          {...field}
          {...props}
          time
          placeholderText={props.placeholder}
          selected={value || (field.value && new Date(field.value)) || null}
          onChange={(val) => {
            if (handleChange) {
              return handleChange(val);
            }
            return setFieldValue(field.name, val);
          }}
          showMonthDropdown={showMonth}
          showYearDropdown={showYear}
          dropdownMode="select"
          maxDate={new Date()}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={timeIntervals}
          timeCaption="Time"
        />
      </div>
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomFileInput({
  field,
  label = '',
  name = '',
  text = 'Upload File',
  form: { touched, errors },
  maxFileSize,
  minFileSize,
  placeholder = '',
  acceptFormat = {
    'image/jpeg': [],
    'image/png': [],
    'application/pdf': [],
  },
  fileRejectionError = '',
  withOutLabel = false,
  requiredField = false,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  const {
    acceptedFiles, fileRejections, getRootProps, getInputProps,
  } = useDropzone({
    accept: acceptFormat,
    onDrop: (acceptedFiles) => {
      setFieldValue(field.name, acceptedFiles);
    },
    maxSize: maxFileSize,
    minSize: minFileSize,
    multiple: false,
  });

  const file = acceptedFiles.map((file) => file.name);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      {errors.map((e) => (
        <div className="invalid-feedback" key={e.code}>
          {fileRejectionError || e.message}
        </div>
      ))}
    </div>
  ));

  return (
    <div className="cursor-pointer">
      {!withOutLabel && (
        <label className="custom-label mb-1" htmlFor={name}>
          {label}
          {' '}
          {requiredField && <span className="mendatory_sign" />}
        </label>
      )}
      <div {...getRootProps({ className: 'file-dummy' })}>
        <img src={UPLOAD_PNG} alt="" />
        <p className="success mt-2">{text}</p>
        <input
          {...field}
          {...props}
          {...getInputProps}
          style={{ display: 'none' }}
          onChange={() => {
            setFieldValue(field.name, acceptedFiles);
          }}
        />
        {placeholder && <div className="red-Alert">{placeholder}</div>}
        <p className="text-green mt-2">{file}</p>
      </div>
      {fileRejectionItems}
      {error && touch && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export function CustomOtpComponent({ ...props }) {
  return <OtpInput {...props} />;
}

export const handleDatePicker = () => 'dd/MM/yyyy';
