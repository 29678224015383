import { useState } from 'react';
import Sidebar from './SideBar';
import Header from './header/Header';
import './layout.css';

function Layout(props) {
  const [menuOpen, setMenuOpen] = useState(true);
  const { children } = props;

  const toggleMenu = () => setMenuOpen(!menuOpen);
  return (
    <>
      <header
        id="page-topbar"
        style={{
          left: menuOpen ? '265px' : '90px',
          transition: 'all ease-in-out 0.4s',
        }}
      >
        <Header toggleMenu={toggleMenu} />
      </header>
      <Sidebar isMenuOpened={menuOpen} openLeftMenuCall={setMenuOpen} />
      <div className={`main-content ${menuOpen ? 'grow-menu' : 'shrink-menu'} `}>
        {children}
      </div>
    </>
  );
}

export default Layout;
