import { BACK_ICON_SVG, BACK_ICON_LIGHT } from 'assets/images';

function CustomModalHeader({
  title = '',
  theme = 'light',
  closeHandle,
  handleBackIcon,
  showTitle = true,
  showBackIcon = false,
}) {
  return (
    <>
      <div className="header2famethod wrapper position-relative  justify-content-start ">
        {showBackIcon && (
          <div className="wrapper backIcon cursor-pointer" onClick={handleBackIcon}>
            <img
              className="h16px"
              src={theme === 'light' ? BACK_ICON_SVG : BACK_ICON_LIGHT}
              alt="back"
            />
            <p className="mb-0 nowrap ms-1 fw-medium"> Back </p>
          </div>
        )}
        <h5 className="modal-title text-center mb-2  w-100">{title}</h5>
      </div>
      {closeHandle && (
        <div onClick={closeHandle} className="closemark cp">
          <i className="fa-solid fa-xmark" />
        </div>
      )}
    </>
  );
}

export default CustomModalHeader;
