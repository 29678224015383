export const USER_LIST = 'USER_LIST';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';

export const USER_OVERVIEW = 'USER_OVERVIEW';
export const USER_OVERVIEW_FAIL = 'USER_OVERVIEW_FAIL';
export const USER_OVERVIEW_SUCCESS = 'USER_OVERVIEW_SUCCESS';

export const USER_EXPORT_LIST = 'USER_EXPORT_LIST';
export const USER_EXPORT_LIST_FAIL = 'USER_EXPORT_LIST_FAIL';
export const USER_EXPORT_LIST_SUCCESS = 'USER_EXPORT_LIST_SUCCESS';

export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';

export const INVESTOR_DETAILS = 'INVESTOR_DETAILS';
export const INVESTOR_DETAILS_FAIL = 'INVESTOR_DETAILS_FAIL';
export const INVESTOR_DETAILS_SUCCESS = 'INVESTOR_DETAILS_SUCCESS';

export const CREATOR_DETAILS = 'CREATOR_DETAILS';
export const CREATOR_DETAILS_FAIL = 'CREATOR_DETAILS_FAIL';
export const CREATOR_DETAILS_SUCCESS = 'CREATOR_DETAILS_SUCCESS';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';

export const SUSPEND_USER = 'SUSPEND_USER';
export const SUSPEND_USER_FAIL = 'SUSPEND_USER_FAIL';
export const SUSPEND_USER_SUCCESS = 'SUSPEND_USER_SUCCESS';

export const SUSPEND_BLOCK_USER_LIST = 'SUSPEND_BLOCK_USER_LIST';
export const SUSPEND_BLOCK_USER_LIST_FAIL = 'SUSPEND_BLOCK_USER_LIST_FAIL';
export const SUSPEND_BLOCK_USER_LIST_SUCCESS = 'SUSPEND_BLOCK_USER_LIST_SUCCESS';

export const EXP_BLOCK_SUSPEND_LIST = 'EXP_BLOCK_SUSPEND_LIST';
export const EXP_BLOCK_SUSPEND_LIST_FAIL = 'EXP_BLOCK_SUSPEND_LIST_FAIL';
export const EXP_BLOCK_SUSPEND_LIST_SUCCESS = 'EXP_BLOCK_SUSPEND_LIST_SUCCESS';
