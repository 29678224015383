/*eslint-disable */
import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import { updateCmsSagaSuccess, updateCmsSagaFail } from 'store/actions';
import { handleToastfunction } from 'utils';
import { getCmsDetailSagaSuccess } from 'store/actions';
import { getCmsDetailSagaFail } from 'store/actions';
import { GET_CMS_DETAILS_URL, UPDATE_CMS_DETAILS_URL } from 'apis';

export function* updateCmsSaga({ payload }) {
  const { contentType, key, value, id } = payload;
  const params = {
    contentType: contentType,
    key: key,
    value: value,
  };
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${UPDATE_CMS_DETAILS_URL}${id}`,
    payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(updateCmsSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(updateCmsSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'patch',
    token: true,
  });
}

export function* getCmsDetailSaga({ payload }) {
  const { contentType } = payload;

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_CMS_DETAILS_URL}=${contentType}`,
    // payload: params,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getCmsDetailSagaSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getCmsDetailSagaFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
