import * as actionLabels from '../../actionLabels';

export const userListSaga = payload => ({
  type: actionLabels.USER_LIST,
  payload,
});

export const userListSagaFail = payload => ({
  type: actionLabels.USER_LIST_FAIL,
  payload,
});

export const userListSagaSuccess = payload => ({
  type: actionLabels.USER_LIST_SUCCESS,
  payload,
});

export const userOverviewSaga = payload => ({
  type: actionLabels.USER_OVERVIEW,
  payload,
});

export const userOverviewSagaFail = payload => ({
  type: actionLabels.USER_OVERVIEW_FAIL,
  payload,
});

export const userOverviewSagaSuccess = payload => ({
  type: actionLabels.USER_OVERVIEW_SUCCESS,
  payload,
});

export const userExportListSaga = payload => ({
  type: actionLabels.USER_EXPORT_LIST,
  payload,
});

export const userExportListSagaFail = payload => ({
  type: actionLabels.USER_EXPORT_LIST_FAIL,
  payload,
});

export const userExportListSagaSuccess = payload => ({
  type: actionLabels.USER_EXPORT_LIST_SUCCESS,
  payload,
});

export const userDetailSaga = payload => ({
  type: actionLabels.USER_DETAILS,
  payload,
});

export const userDetailSagaFail = payload => ({
  type: actionLabels.USER_DETAILS_FAIL,
  payload,
});

export const userDetailSagaSuccess = payload => ({
  type: actionLabels.USER_DETAILS_SUCCESS,
  payload,
});

export const investorDetailSaga = payload => ({
  type: actionLabels.INVESTOR_DETAILS,
  payload,
});

export const investorDetailSagaFail = payload => ({
  type: actionLabels.INVESTOR_DETAILS_FAIL,
  payload,
});

export const investorDetailSagaSuccess = payload => ({
  type: actionLabels.INVESTOR_DETAILS_SUCCESS,
  payload,
});

export const creatorDetailSaga = payload => ({
  type: actionLabels.CREATOR_DETAILS,
  payload,
});

export const creatorDetailSagaFail = payload => ({
  type: actionLabels.CREATOR_DETAILS_FAIL,
  payload,
});

export const creatorDetailSagaSuccess = payload => ({
  type: actionLabels.CREATOR_DETAILS_SUCCESS,
  payload,
});

export const blockUserSaga = payload => ({
  type: actionLabels.BLOCK_USER,
  payload,
});

export const blockUserSagaFail = payload => ({
  type: actionLabels.BLOCK_USER_FAIL,
  payload,
});

export const blockUserSagaSuccess = payload => ({
  type: actionLabels.BLOCK_USER_SUCCESS,
  payload,
});

export const suspendUserSaga = payload => ({
  type: actionLabels.SUSPEND_USER,
  payload,
});

export const suspendUserSagaFail = payload => ({
  type: actionLabels.SUSPEND_USER_FAIL,
  payload,
});

export const suspendUserSagaSuccess = payload => ({
  type: actionLabels.SUSPEND_USER_SUCCESS,
  payload,
});

export const blockSuspListSaga = payload => ({
  type: actionLabels.SUSPEND_BLOCK_USER_LIST,
  payload,
});

export const blockSuspListSagaFail = payload => ({
  type: actionLabels.SUSPEND_BLOCK_USER_LIST_FAIL,
  payload,
});

export const blockSuspListSagaSuccess = payload => ({
  type: actionLabels.SUSPEND_BLOCK_USER_LIST_SUCCESS,
  payload,
});

export const expblockSuspListSaga = payload => ({
  type: actionLabels.EXP_BLOCK_SUSPEND_LIST,
  payload,
});

export const expblockSuspListSagaFail = payload => ({
  type: actionLabels.EXP_BLOCK_SUSPEND_LIST_FAIL,
  payload,
});

export const expblockSuspListSagaSuccess = payload => ({
  type: actionLabels.EXP_BLOCK_SUSPEND_LIST_SUCCESS,
  payload,
});
