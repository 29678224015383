import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
    userInfoSagaSuccess,
    userInfoSagaFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import { GET_USER_INFO_URL } from 'apis';

export function* userInfoSaga({ payload }) {   
    yield errorHandler({
      endpoint: `${process.env.REACT_APP_END_POINT}${GET_USER_INFO_URL}`,
      successHandler: yield function* (response) {
        const { data } = response;
        yield put(userInfoSagaSuccess(data));
      },
      failHandler: yield function* (response) {
        yield handleToastfunction({ status: 'error', title: response });
        yield put(userInfoSagaFail(response));
      },
      failHandlerType: 'CUSTOM',
      baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
      apiType: 'get',
      token: true,
    });
  }
