import { combineReducers } from 'redux';
import auth from './auth/auth';
import user from './user/user';
import dashboard from './dashboard/dashboard';
import project from './project/project';
import profile from './profile/profile';
import platform from './platform/platform';
import transaction from './transaction/transaction';
import notification from './notification/notification';
import cms from './cms/cms';
import affiliate from './affiliate/affiliate';
import swap from './swap/swap';

const allReducers = combineReducers({
  auth,
  user,
  dashboard,
  project,
  profile,
  platform,
  transaction,
  notification,
  cms,
  affiliate,
  swap,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
