// AUTH
export const LOGIN_URL = '/auth/login';
export const CHANGE_PASSWORD_URL = '/auth/change-password';
export const LANGUAGE_CHOOSE_URL = '/auth/language';

// CMS
export const GET_CMS_DETAILS_URL = '/cms/get?contentType';
export const UPDATE_CMS_DETAILS_URL = '/cms/update/';

// DASHBOARD
export const DASHBOARD_OVERVIEW_URL = '/dashboard/overview';
export const DASHBOARD_STATISTICS_URL = '/dashboard/statics';
export const DASHBOARD_GRAPH_URL = '/dashboard/chart';

// NOTIFICATION
export const GET_NOTIFICATION_URL = '/notification';
export const CLEAR_NOTIFICATION_URL = '/notification/remove';

// PLATFORM
export const PLATFORM_HISTORY_URL = '/platform-variable/get-platform-variable-history';
export const PLATFORM_VARIABLE_OVERVIEW_URL = '/platform-variable/get-platform-variable';

// PROFILE
export const GET_USER_INFO_URL = '/auth/me';

// PROJECT
export const PROJECT_OVERVIEW_URL = '/project/overview';
export const GET_PROJECT_LIST_URL = '/project';
export const DELETE_PROJECT_LIST_URL = '/project/delete-project';
export const PROJECT_APPROVE_URL = '/project/approval/';
export const PROJECT_REJECT_URL = '/project/reject/';
export const PROJECT_DETAILS_URL = '/project/details/';
export const UPLOAD_IMAGE_URL = '/project/upload-file';
export const UPDATE_PROJECT_URL = '/project/update/';
export const DELETE_PROJECT_URL = '/project/delete-project/';

// USER
export const GET_USER_LIST_URL = '/user';
export const USER_OVERVIEW_URL = '/user/overview';
export const USER_EXPORT_LIST_URL = '/user/export-list';
export const USER_DETAILS_URL = '/user/details/';
export const INVESTOR_DETAILS_URL = '/user/investor-details/';
export const CREATOR_DETAILS_URL = '/user/creator-details/';
export const BLOCK_USER_URL = '/user/block/';
export const SUSPEND_USER_URL = '/user/suspend/';
export const SUSPEND_BLOCK_USER_LIST_URL = '/user/inactive-user';
export const EXP_SUSPEND_BLOCK_LIST_URL = '/user/export-inactive-user';

// TRANSACTION
export const GET_TOKEN_GEN_FEE_URL = '/transaction/token-generation-fee';
export const GET_PLATFORM_FEE_URL = '/transaction/platform-fee';
export const GET_LISTING_FEE_URL = '/transaction/listing-fee';
export const GET_TRANSACTION_LIST_URL = '/transaction/user-transaction';
export const TRANSACTION_EXPORT_LIST_URL = '/transaction/export-user-transaction';
export const EXPORT_LISTING_FEE_URL = '/transaction/export-listing-fee';
export const EXPORT_TOKEN_GEN_FEE_URL = '/transaction/export-token-generation-fee';
export const EXPORT_PLATFORM_FEE_URL = '/transaction/export-platform-fee';

// affiliate
export const GET_AFFILIATE_LIST_URL = '/affiliate/affiliate-list';
export const GET_AFFILIATE_INFO_URL = '/affiliate/affiliate-info';
export const GET_AFFILIATE_DETAILS_URL = '/affiliate/affiliate-details';
export const GET_AFFILIATE_INVESTMENT_URL = '/affiliate/affiliate-investment';
export const EXPORT_AFFILIATE_INVESTMENT_URL = '/affiliate/export-investment';

// swap
export const GET_SWAP_LIST_URL = '/swap/get';
