import ProfileMenu from 'components/ProfileMenu';
import './header.css';

function Header({ toggleMenu }) {
  return (
    <div className="navbar-header">
      <button
        type="button"
        className="btn btn-sm px-3 font-size-20 header-item waves-effect waves-light fs-4"
        data-toggle="collapse"
        onClick={toggleMenu}
        data-target="#topnav-menu-content"
      >
        <i className="fa fa-fw fa-bars bars_color" />
      </button>
      <ProfileMenu />
    </div>
  );
}

export default Header;
