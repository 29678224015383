import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the necessary elements
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

function LineChart() {
    const data = {
        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        datasets: [
            {
                // data: [65, 59, 80, 81, 56, 55, 40],
                data: [0, 1000, 2000, 3000],
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const options = {
      responsive: true,
      plugins: {
          legend: {
              display: false,
          },
          title: {
              display: false,
          },
          tooltip: {
              callbacks: {
                  title: (tooltipItems) => {
                      const index = tooltipItems[0].dataIndex;
                      return monthNames[index];
                  },
                  label: (tooltipItem) => `Value: ${tooltipItem.raw}`,
              },
          },
      },
      scales: {
          x: {
              display: true,
              title: {
                  display: true,
              },
          },
          y: {
              display: true,
              title: {
                  display: true,
              },
              ticks: {
                callback(value) {
                    // Show only specific values
                    if (value === 0 || value === 1000 || value === 2000 || value === 3000) {
                        return value;
                    }
                    return null;
                },
                stepSize: 1000, // Ensure steps of 1000
            },
          },
      },
  };

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    return (
        <div>
            <Line data={data} options={options} />
        </div>
    );
}

export default LineChart;
