import { Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import GuestHeader from 'views/Layout/header/GuestHeader';
import { guestRoutes } from './routes';
import * as actions from './store/actions';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './app.css';
import { CryptoSpinner } from 'components';
import MainContainer from './views/MainContainer/MainContainer';

function App() {
  const dispatch = useDispatch();
  const { Theme, authToken } = useSelector(state => state.auth);
  const pathname = window.location.pathname.split('/')[1];
  const redirectHandler = () => {
    const guestRoutePaths = guestRoutes.filter(item => !item.redirectRoute).map(item => item.path);
    return !guestRoutePaths.includes(`/${pathname}`) && !sessionStorage.getItem('authToken') ? (
      <Redirect to="/signin" />
    ) : null;
  };
  let mainContent = (
    <>
      {guestRoutes.map(route => (
        <Route key={route.name} path={route.path} exact={route.exact} name={route.name}>
          <GuestHeader>
            <route.component />
          </GuestHeader>
        </Route>
      ))}
      {redirectHandler()}
    </>
  );
  
  if (authToken) {
    mainContent = <Route path="/" component={MainContainer} />;
  }

  useEffect(() => {
    if (authToken) {
      dispatch(actions.userInfoSaga());
    }
  }, [authToken]);

  return (
    <Suspense fallback={<CryptoSpinner />}>
      <div className={authToken ? 'mainbody' : 'authbody'} data-theme={Theme}>
        <Router>
          <Switch>{mainContent}</Switch>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
