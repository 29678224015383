/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  notificationsList: {},
  isRemoveComplete: false,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.GET_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
      };
    case actionLabels.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationsList: payload,
        isLoading: false,
      };
    case actionLabels.GET_NOTIFICATION_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        content: {},
      };
    }
    case actionLabels.CLEAR_NOTIFICATION:
      return {
        ...state,
        isRemoveComplete: false,
        isLoading: true,
      };
    case actionLabels.CLEAR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isRemoveComplete: true,
        isLoading: false,
      };
    case actionLabels.CLEAR_NOTIFICATION_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
        isRemoveComplete: false,
        content: {},
      };
    }
    default:
      return state;
  }
};
