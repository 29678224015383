import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import {
  getAffiliateListSuccess,
  getAffiliateListFail,
  getAffiliateDetailsFail,
  getAffiliateDetailsSuccess,
  getAffiliateInfoSuccess,
  getAffiliateInfoFail,
  getAffiliateInvestmentSuccess,
  getAffiliateInvestmentFail,
  exportAffiliateInvestmentSuccess,
  exportAffiliateInvestmentFail,
} from 'store/actions';
import { handleToastfunction } from 'utils';
import {
  GET_AFFILIATE_LIST_URL,
  GET_AFFILIATE_INFO_URL,
  GET_AFFILIATE_DETAILS_URL,
  GET_AFFILIATE_INVESTMENT_URL,
  EXPORT_AFFILIATE_INVESTMENT_URL,
} from 'apis';

export function* getAffiliateList({ payload }) {
  const { itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_AFFILIATE_LIST_URL}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getAffiliateListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getAffiliateListFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getAffiliateDetails({ payload }) {
  const { id, itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_AFFILIATE_DETAILS_URL}/${id}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getAffiliateDetailsSuccess(response));
    },
    failHandler: yield function* (response) {
      // yield handleToastfunction({ status: 'error', title: response });
      yield put(getAffiliateDetailsFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getAffiliateInfo({ payload }) {
  const { id } = payload;

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_AFFILIATE_INFO_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getAffiliateInfoSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getAffiliateInfoFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* getAffiliateInvestment({ payload }) {
  const { id, itemsPerPage, startIndex, search, userType, startDate, endDate, sortby, sortValue } =
    payload;
  const params = { itemsPerPage, startIndex };
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }

  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${GET_AFFILIATE_INVESTMENT_URL}/${id}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getAffiliateInvestmentSuccess(response));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(getAffiliateInvestmentFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

export function* exportAffiliateInvestment({ payload }) {
  const { search, startDate, endDate, userType, id, sortby, sortValue } = payload;
  const params = {};
  if (search) {
    params.search = search;
  }
  if (userType) {
    params.userType = userType;
  }
  if (startDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (sortby) {
    params.sortBy = sortby;
    params.sortValue = sortValue;
  }
  yield errorHandler({
    endpoint: `${process.env.REACT_APP_END_POINT}${EXPORT_AFFILIATE_INVESTMENT_URL}/${id}`,
    params: JSON.stringify(params),
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(exportAffiliateInvestmentSuccess(data));
    },
    failHandler: yield function* (response) {
      yield handleToastfunction({ status: 'error', title: response });
      yield put(exportAffiliateInvestmentFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
