/*eslint-disable */
import io from 'socket.io-client';

const baseURL = process.env.REACT_APP_SOCKET_END_POINT_URL;
let socket = undefined;
const initiatesocket = callback => {
  const token = localStorage.getItem('authToken');
  socket = io(`${baseURL}/`, {
    path: '/connect-socket',
    extraHeaders: {
      authorization: token,
    },
    rejectUnauthorized: false,
  }).on('connect', () => {
    callback();
  });
};
const SOCKET_EVENTS = {
  JOIN: 'join', //emit
  MARK_AS_READ: 'mark_as_read', //emit
  MARK_ALL_AS_READ: 'mark_all_as_read', //emit
  NOTIFICATIONS_LIST: 'notification_list', // not req / cancel
  NOTIFICATION: 'notification', //on
  CONNECTION: 'connection',
  DELETE: 'delete',
  DISCONNECT: 'disconnect',
};

export { SOCKET_EVENTS, socket, initiatesocket };


