import * as actionLabels from '../../actionLabels';

export const userInfoSaga = payload => ({
    type: actionLabels.USER_INFO,
    payload,
  });
  
  export const userInfoSagaFail = payload => ({
    type: actionLabels.USER_INFO_FAIL,
    payload,
  });
  
  export const userInfoSagaSuccess = payload => ({
    type: actionLabels.USER_INFO_SUCCESS,
    payload,
  });
