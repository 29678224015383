import * as actionLabels from '../../actionLabels';

export const platformHistorySaga = payload => ({
  type: actionLabels.PLATFORM_HISTORY,
  payload,
});

export const platformHistorySagaFail = payload => ({
  type: actionLabels.PLATFORM_HISTORY_FAIL,
  payload,
});

export const platformHistorySagaSuccess = payload => ({
  type: actionLabels.PLATFORM_HISTORY_SUCCESS,
  payload,
});

export const getPlatformVariableSaga = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLE,
  payload,
});

export const getPlatformVariableSagaFail = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLE_FAIL,
  payload,
});

export const getPlatformVariableSagaSuccess = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLE_SUCCESS,
  payload,
});
